<template>
  <section class="schedule" id="schedule">
     <div class="schedule__wrapper container">
      <div class="schedule__titleBlock">
        <h3 class="schedule__title">Расписание</h3>
        <h3 class="schedule__title schedule__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.104 0.640625L61.7982 5.94712L68.0151 12.1648L0.599609 12.1648V19.669L68.0151 19.669L61.7982 25.8867L67.104 31.1932L82.3784 15.9169L67.104 0.640625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.523438L31.4001 3.17669L34.5085 6.28554L0.800781 6.28554V10.0376L34.5085 10.0376L31.4001 13.1465L34.053 15.7997L41.6902 8.16158L34.053 0.523438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth <= 430"
            width="28"
            height="20"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2761 0L14.9501 3.32649L18.8473 7.2242L0 7.22419V11.9283L18.8473 11.9283L14.9501 15.826L18.2761 19.1525L27.8513 9.57626L18.2761 0Z"
              fill="#E6E6E6"
            />
          </svg>
          <span>
            KSS PARK
            <span v-if="screenWidth <= 430">, М. Нагорная</span>
          </span>
        </h3>
      </div>
      <button v-if="screenWidth > 640" class="schedule__linkToRules" @click="openHoverRules">
        <svg v-if="screenWidth > 1686" width="16" height="16" fill="none">
          <circle cx="7.839" cy="8.144" r="7.839" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1686 && screenWidth >= 1356"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.87275" cy="7.25557" r="6.27119" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1355 && screenWidth >= 640"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.93638" cy="3.86216" r="3.1356" fill="#0091FF" />
        </svg>
        Правила посещения парка
      </button>
    </div>
    <div v-if="screenWidth > 640" class="hoverRules__wrapper container">
      <HoverRulesComponent v-show="isShowHoverRules" />
    </div>
    <div class="schedule__table container">
      <!-- <FreeDrivingScheduleComponent v-if="screenWidth > 640" /> -->
      <!-- <img v-if="screenWidth <= 430" src="@/assets/img/scheduleMobile.svg" alt="Расписание" /> -->
      <img src="@/assets/img/groupSchedule.webp" alt="Расписание" />
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" />
  <AboutParkRulesComponent :screenWidth="screenWidth" />

  <!-- <AdditionalComponent
    :additional="additional"
    :screenWidth="screenWidth"
    title="стоимость"
    subtitle="свободное катание"
  /> -->

<section class="price" id="price">
    <div class="price__wrapper container">
      <div class="price__titleBlock">
        <h3 class="price__title">свободное</h3>
        <h3 class="price__title price__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.104 0.25L61.7982 5.5565L68.0151 11.7742L0.599609 11.7742V19.2783L68.0151 19.2783L61.7982 25.4961L67.104 30.8026L82.3784 15.5263L67.104 0.25Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.125L31.4001 2.77825L34.5085 5.88711L0.800781 5.8871V9.63917L34.5085 9.63917L31.4001 12.748L34.053 15.4013L41.6902 7.76314L34.053 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="28.462" height="20.947" fill="none">
            <path
              fill="#DFDFDF"
              d="M17.99 0 14.35 3.638l4.263 4.263H0v5.145h18.614L14.35 17.31l3.638 3.638 10.473-10.473L17.989 0Z"
            />
          </svg>
          катание
        </h3>
      </div>
      <PriceComponent :price="price" v-if="screenWidth > 1686" />
      <PriceComponentTablet :price="price" v-if="screenWidth >= 640 && screenWidth <= 1686" />
      <PriceComponentMobile :price="price" v-if="screenWidth <= 430" />
    </div>
  </section>

  <LineComponent v-if="screenWidth <= 430" />
  <section class="feedback" v-if="screenWidth <= 430">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import HoverRulesComponent from '@/components/HoverRulesComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import FreeDrivingScheduleComponent from '@/components/FreeDrivingScheduleComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import AdditionalComponent from '@/components/AdditionalComponent.vue';
import PriceComponent from '@/components/PriceComponent.vue';

export default {
  components: {
    HoverRulesComponent,
    AboutParkRulesComponent,
    FreeDrivingScheduleComponent,
    FeedbackSliderComponent,
    LineComponent,
    AdditionalComponent,
		PriceComponent
  },
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({ price: (state) => state.price.additionalFreeDriving }),
    ...mapState(['isShowHoverRules']),
  },

  methods: {
    ...mapMutations(['openHoverRules']),
  },
};
</script>

<style lang="scss" scoped></style>
