import { v4 as uuidv4 } from 'uuid';

const partners = {
  state: {
    partners: [
      {
        id: uuidv4(),
        title: 'Яндекс',
        img: require('@/assets/img/Partners/logo1.png'),
      },
      {
        id: uuidv4(),
        title: 'Департамент спорта города Москвы',
        img: require('@/assets/img/Partners/logo2.png'),
      },
      {
        id: uuidv4(),
        title: 'Московский спорт',
        img: require('@/assets/img/Partners/logo3.png'),
      },
      {
        id: uuidv4(),
        title: 'Первые',
        img: require('@/assets/img/Partners/logo4.png'),
      },
      {
        id: uuidv4(),
        title: 'Росмоложёжь',
        img: require('@/assets/img/Partners/logo5.png'),
      },
      {
        id: uuidv4(),
        title: 'fsspoert.ru',
        img: require('@/assets/img/Partners/logo6.png'),
      },
      {
        id: uuidv4(),
        title: 'logo7',
        img: require('@/assets/img/Partners/logo7.png'),
      },
      {
        id: uuidv4(),
        title: 'Федерация самокатного спорта',
        img: require('@/assets/img/Partners/logo8.png'),
      },
      {
        id: uuidv4(),
        title: 'Урам',
        img: require('@/assets/img/Partners/logo9.png'),
      },
      {
        id: uuidv4(),
        title: 'Вконтакте',
        img: require('@/assets/img/Partners/logo10.png'),
      },
      {
        id: uuidv4(),
        title: 'RampTroy',
        img: require('@/assets/img/Partners/logo11.png'),
      },
    ],

    partnersMobile: [
      {
        id: uuidv4(),
        title: 'Яндекс',
        img: require('@/assets/img/Partners/Mobile/logo1.svg'),
      },
      {
        id: uuidv4(),
        title: 'Департамент спорта города Москвы',
        img: require('@/assets/img/Partners/Mobile/logo2.svg'),
      },
      {
        id: uuidv4(),
        title: 'Московский спорт',
        img: require('@/assets/img/Partners/Mobile/logo3.svg'),
      },
      {
        id: uuidv4(),
        title: 'Первые',
        img: require('@/assets/img/Partners/Mobile/logo4.svg'),
      },
      {
        id: uuidv4(),
        title: 'Росмоложёжь',
        img: require('@/assets/img/Partners/Mobile/logo5.svg'),
      },
      {
        id: uuidv4(),
        title: 'fsspoert.ru',
        img: require('@/assets/img/Partners/Mobile/logo6.svg'),
      },
      {
        id: uuidv4(),
        title: 'logo7',
        img: require('@/assets/img/Partners/Mobile/logo7.svg'),
      },
      {
        id: uuidv4(),
        title: 'Федерация самокатного спорта',
        img: require('@/assets/img/Partners/Mobile/logo8.svg'),
      },
      {
        id: uuidv4(),
        title: 'Урам',
        img: require('@/assets/img/Partners/Mobile/logo9.svg'),
      },
      {
        id: uuidv4(),
        title: 'Вконтакте',
        img: require('@/assets/img/Partners/Mobile/logo10.svg'),
      },
      {
        id: uuidv4(),
        title: 'RampTroy',
        img: require('@/assets/img/Partners/Mobile/logo11.svg'),
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default partners;
