const advantagesCards = {
	state: {
		individual: [
			{
				id: 1,
				title: 'Адаптированная программа',
				text: 'Индивидуальные тренировки подходят для любого возраста и уровня подготовки. Тренер выстроит программу с учетом уровня катания, физической формы, личных целей и пожеланий.',
			},
			{
				id: 2,
				title: 'Постоянный контроль',
				text: 'Тренер всегда подстрахует, будет следить за правильностью выполнения трюков пошагово разберёт ошибки, допускаемые в процессе тренировки.',
			},
			{
				id: 3,
				title: 'Мотивация',
				text: 'Личный наставник помогает справляться с нагрузками и мотивирует продолжать тренировки, доводя начатое до конца.',
			},
		],
		group: [
			{
				id: 1,
				title: 'Социализация',
				text: 'Индивидуальные тренировки подходят для любого возраста и уровня подготовки. Тренер выстроит программу с учетом уровня катания, физической формы, личных целей и пожеланий.',
			},
			{
				id: 2,
				title: 'мотивация',
				text: 'Личный наставник помогает справляться с нагрузками и мотивирует продолжать тренировки, доводя начатое до конца.',
			},
			{
				id: 3,
				title: 'дневник прогресса',
				text: 'Личный наставник помогает справляться с нагрузками и мотивирует продолжать тренировки, доводя начатое до конца.',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default advantagesCards;
