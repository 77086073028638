import { v4 as uuidv4 } from 'uuid';

const price = {
  state: {
    individual: [
      {
        id: uuidv4(),
        title: 'Персональная тренировка',
        people: `
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>		
				`,
        quantity: '1 тренировка / 1 час',
        quantityMobile: '1 тренировка / 1 час',
        duration: '',
        currentPrice: 2200,
      },
      {
        id: uuidv4(),
        title: 'Сплит-тренировка',
        people: `
					<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>
				`,
        quantity: '1 тренировка / 1 час',
        quantityMobile: '2 человека / 1 тренировка / 1 час',
        duration: '',
        currentPrice: 3000,
      },
      {
        id: uuidv4(),
        title: 'Абонемент «Стандарт»*',
        people: `
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>		
				`,
        quantity: '3 тренировки',
        quantityMobile: '3 тренировки',
        duration: '*Срок действия абонемента 1 месяца с момента активации',
        currentPrice: 6200,
      },
      {
        id: uuidv4(),
        title: 'Абонемент «Оптимальный»*',
        people: `
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>		
				`,
        quantity: '5 тренировок',
        quantityMobile: '5 тренировок',
        duration: '*Срок действия абонемента 2 месяца с момента активации',
        currentPrice: 10000,
      },
      {
        id: uuidv4(),
        title: 'Абонемент «Максимальный»*',
        people: `
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							<mask id="mask0_1604_2973" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
							<circle cx="17.5018" cy="18.1024" r="17.3671" fill="#0091FF"/>
							</mask>
							<g mask="url(#mask0_1604_2973)">
							<circle cx="17.5015" cy="14.6739" r="6.75784" fill="white"/>
							<circle cx="17.5018" cy="40.1454" r="16.6991" fill="white"/>
							</g>
						</svg>		
				`,
        quantity: '10 тренировок',
        quantityMobile: '10 тренировок',
        duration: '*Срок действия абонемента 3 месяца с момента активации',
        currentPrice: '19 500',
      },
    ],
    group: [
      {
        id: uuidv4(),
        title: 'Разовая тренировка в группе',
        people: ``,
        quantity: '1 тренировка / 2 часа',
        quantityMobile: '1 тренировка / 2 часа',
        duration: '',
        currentPrice: 1200,
      },
      {
        id: uuidv4(),
        title: 'Абонемент «Стандарт»*',
        people: ``,
        quantity: '4 тренировки',
        quantityMobile: '4 тренировки',
        duration: '*Срок действия абонемента 30 дней с момента активации',
        currentPrice: 3400,
      },
      {
        id: uuidv4(),
        title: 'Абонемент «Оптимальный»*',
        people: ``,
        quantity: '8 тренировок',
        quantityMobile: '8 тренировок',
        duration: '*Срок действия абонемента 30 дней с момента активации',
        currentPrice: 6400,
      },
    ],
    park: [
      {
        id: uuidv4(),
        title: '20:00 - 22:00',
        people: ``,
        quantity: '2 часа аренды парка',
        quantityMobile: '',
        duration: '*Оплата принимается наличными и картой',
        currentPrice: 6000,
      },
      {
        id: uuidv4(),
        title: '20:00 - 23:00',
        people: ``,
        quantity: '3 часа аренды парка',
        quantityMobile: '',
        duration: '*Оплата принимается наличными и картой',
        currentPrice: 8000,
      },
      {
        id: uuidv4(),
        title: '20:00 - 00:00',
        people: ``,
        quantity: '4 часа аренды парка',
        quantityMobile: '',
        duration: '*Оплата принимается наличными и картой',
        currentPrice: 10000,
      },
      {
        id: uuidv4(),
        title: '23:00 - 6:00',
        people: ``,
        quantity: '7 часа аренды парка',
        quantityMobile: '',
        duration: '*Оплата принимается наличными и картой',
        currentPrice: 20000,
      },
    ],
    additional: [
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда шлема',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда защиты',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/scooter.svg'),
        title: 'Аренда самоката',
        time: 'Весь день',
        price: '500 ₽',
      },
    ],
    additionalFreeDriving: [
      {
        id: uuidv4(),
        title: 'Абонемент "Минимум"*',
        people: ``,
        quantity: '5 посещений',
        quantityMobile: '5 посещений',
        duration: '	*Срок действия абонементов 30 дней с момента активации',
        currentPrice: 1900,
      },
      {
        id: uuidv4(),
        title: 'Абонемент "Стандарт"*',
        people: ``,
        quantity: '10 посещений',
        quantityMobile: '10 посещений',
        duration: '	*Срок действия абонементов 30 дней с момента активации',
        currentPrice: 3600,
      },
      {
        id: uuidv4(),
        title: 'Абонемент "Оптимальный"*',
        people: ``,
        quantity: '15 посещений',
        quantityMobile: '15 посещений',
        duration: '	*Срок действия абонементов 30 дней с момента активации',
        currentPrice: 5200,
      },
      {
        id: uuidv4(),
        title: 'Абонемент "Максимальный"*',
        people: ``,
        quantity: '20 посещений',
        quantityMobile: '20 посещений',
        duration: '	*Срок действия абонементов 30 дней с момента активации',
        currentPrice: 6400,
      },
    ],
    additionalFull: [
      {
        id: uuidv4(),
        pic: require('@/assets/img/coach.svg'),
        title: 'Тренер',
        time: '1 час',
        price: '4000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/coach.svg'),
        title: 'Тренер',
        time: '3 часа',
        price: '10 000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда шлема',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда защиты',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/scooter.svg'),
        title: 'Аренда самоката',
        time: 'Весь день',
        price: '500 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/photoCamera.svg'),
        title: 'Фотосъемка',
        time: '1 час',
        price: '5000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/videoCamera.svg'),
        title: 'Фотосъемка',
        time: '1 час',
        price: '10 000 ₽',
      },
    ],
    additionalEvent: [
      {
        id: uuidv4(),
        pic: require('@/assets/img/clock.svg'),
        title: 'Дополнительное время аренды',
        time: '1 час',
        price: '2000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/coach.svg'),
        title: 'Тренер',
        time: '1 час',
        price: '4000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/coach.svg'),
        title: 'Тренер',
        time: '3 часа',
        price: '10 000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда шлема',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/helmet.svg'),
        title: 'Аренда защиты',
        time: 'Весь день',
        price: '100 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/scooter.svg'),
        title: 'Аренда самоката',
        time: 'Аренда инвентаря на весь день',
        price: '500 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/photoCamera.svg'),
        title: 'Фотосъемка',
        time: '1 час',
        price: '5000 ₽',
      },
      {
        id: uuidv4(),
        pic: require('@/assets/img/videoCamera.svg'),
        title: 'Фотосъемка',
        time: '1 час',
        price: '10 000 ₽',
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default price;
