<template>
  <section class="mediaPage">
    <div class="mediaPage__wrapper container">
      <div class="mediaPage__titleBlock">
        <h1 class="mediaPage__title">медиа</h1>
        <h2 class="mediaPage__title mediaPage__title--lightblue">
          <svg v-if="screenWidth > 1686" width="111" height="86" fill="none">
            <path
              fill="#C5E6FF"
              d="m67.927 0-14.82 14.822 17.365 17.366H0v20.96h70.472L53.108 70.515l14.82 14.822 42.663-42.669L67.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="89"
            height="69"
            viewBox="0 0 89 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M54.5411 0.59375L42.6853 12.4511L56.577 26.3445L0.199219 26.3445V43.1124L56.577 43.1124L42.6853 57.0059L54.5411 68.8632L88.6717 34.7285L54.5411 0.59375Z"
              fill="#C5E6FF"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="45"
            height="35"
            viewBox="0 0 45 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.7706 0.800781L21.8426 6.72944L28.7885 13.6762L0.599609 13.6762V22.0601L28.7885 22.0601L21.8426 29.0068L27.7706 34.9355L44.8358 17.8681L27.7706 0.800781Z"
              fill="#C5E6FF"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="48.978" height="37.793" fill="none" viewBox="0 0 48.977 37.793">
            <path
              fill="#C5E6FF"
              d="M30.083 0 23.52 6.564l7.69 7.691H0v9.283h31.21l-7.69 7.691 6.563 6.564 18.895-18.896L30.082 0Z"
            />
          </svg>
          kss school
        </h2>
      </div>
    </div>
  </section>
  <div class="mediaPage__yearsList" v-if="screenWidth > 640">
    <img
      v-if="currentYear === 2024"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2024.webp"
      alt="2024"
    />
    <img
      v-if="currentYear === 2023"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2023.webp"
      alt="2023"
    />
    <img
      v-if="currentYear === 2022"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2022.webp"
      alt="2022"
    />
    <img
      v-if="currentYear === 2021"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2021.webp"
      alt="2021"
    />
    <img
      v-if="currentYear === 2020"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2020.webp"
      alt="2020"
    />
    <img
      v-if="currentYear === 2019"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2019.webp"
      alt="2019"
    />
    <img
      v-if="currentYear === 2018"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2018.webp"
      alt="2018"
    />
    <img
      v-if="currentYear === 2017"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2017.webp"
      alt="2017"
    />
    <img
      v-if="currentYear === 2016"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2016.webp"
      alt="2016"
    />
    <img
      v-if="currentYear === 2015"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2015.webp"
      alt="2015"
    />
    <img
      v-if="currentYear === 2014"
      class="mediaPage__yearsList-panel"
      src="@/assets/img/Media/Panel/2014.webp"
      alt="2014"
    />
    <button
      v-for="(link, index) in links"
      :key="link.id"
      @click.prevent="toggleActive(link)"
      :class="'mediaPage__yearsList-btn' + (index + 1) + (link.active ? ' ' + link.class : '')"
    >
      <svg v-if="link.active && screenWidth > 1686" width="21" height="12" fill="none">
        <path
          fill="#70C1FF"
          d="m14.64 0-2.02 2.02 2.366 2.365H0V7.24h14.986L12.62 9.606l2.02 2.019 5.81-5.813L14.64 0Z"
        />
      </svg>
      <svg
        v-if="link.active && screenWidth >= 1356 && screenWidth <= 1686"
        width="17"
        height="11"
        viewBox="0 0 17 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7113 0.796875L10.0963 2.41213L11.9886 4.30475L0 4.30475V6.58895L11.9886 6.58895L10.0963 8.48157L11.7113 10.0968L16.3607 5.44685L11.7113 0.796875Z"
          fill="#70C1FF"
        />
      </svg>
      <svg
        v-if="link.active && screenWidth >= 640 && screenWidth <= 1355"
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.85565 0.398438L5.04813 1.20606L5.99432 2.15237L0 2.15237V3.29447L5.99432 3.29447L5.04813 4.24078L5.85565 5.04841L8.18035 2.72342L5.85565 0.398438Z"
          fill="#70C1FF"
        />
      </svg>
      {{ link.title }}
    </button>
  </div>

  <!-- Мобильная версия панели -->

  <div class="mediaPage__yearsList" v-if="screenWidth <= 430">
    <img
      class="mediaPage__yearsList-panel mediaPage__yearsList-panelLeft"
      src="@/assets/img/Media/Panel/MobileLeft.webp"
      alt="panel"
    />
    <img
      class="mediaPage__yearsList-panel mediaPage__yearsList-panelRight"
      src="@/assets/img/Media/Panel/MobileRight.webp"
      alt="panel"
    />
    <div class="mediaPage__yearsList-MobileMenu">
      <span>{{ currentYear }}</span>
      <button class="mediaPage__yearsList-MobileMenu__arrowButton" @click="isShow = !isShow">
        <img v-if="!isShow" src="@/assets/img/Media/Panel/ArrowBottom.svg" alt="button" />
        <img v-else src="@/assets/img/Media/Panel/ArrowTop.svg" alt="button" />
      </button>
      <nav class="mediaPage__yearsList-MobileMenu__navigationWrapper" v-show="isShow">
        <button
          v-for="(link, index) in links"
          :key="link.id"
          @click.prevent="toggleActive(link)"
          class="navigationWrapper__btn"
        >
          {{ link.title }}
        </button>
      </nav>
    </div>
    <p>Нажмите на стрелку, чтобы увидеть материалы других лет</p>
  </div>

  <div class="container">
    <div v-if="currentYear === 2024" class="mediaPage__photos2024 mediaPage__photosMobile">
      <MediaComponent :photos="year2024" />
    </div>
    <div v-if="currentYear === 2023" class="mediaPage__photos2023 mediaPage__photosMobile">
      <MediaComponent :photos="year2023" />
    </div>
    <div v-if="currentYear === 2022" class="mediaPage__photos2022 mediaPage__photosMobile">
      <MediaComponent :photos="year2022" />
    </div>
    <div v-if="currentYear === 2021" class="mediaPage__photos2021 mediaPage__photosMobile">
      <MediaComponent :photos="year2021" />
    </div>
    <div v-if="currentYear === 2020" class="mediaPage__photos2020 mediaPage__photosMobile">
      <MediaComponent :photos="year2020" />
    </div>
    <div v-if="currentYear === 2019" class="mediaPage__photos2019 mediaPage__photosMobile">
      <MediaComponent :photos="year2019" />
    </div>
    <div v-if="currentYear === 2018" class="mediaPage__photos2018 mediaPage__photosMobile">
      <MediaComponent :photos="year2018" />
    </div>
    <div v-if="currentYear === 2017" class="mediaPage__photos2017 mediaPage__photosMobile">
      <MediaComponent :photos="year2017" />
    </div>
    <div v-if="currentYear === 2016" class="mediaPage__photos2016 mediaPage__photosMobile">
      <MediaComponent :photos="year2016" />
    </div>
    <div v-if="currentYear === 2015" class="mediaPage__photos2015 mediaPage__photosMobile">
      <MediaComponent :photos="year2015" />
    </div>
    <div v-if="currentYear === 2014" class="mediaPage__photos2014 mediaPage__photosMobile">
      <MediaComponent :photos="year2014" />
    </div>
  </div>
  <LineComponent />
  <section class="events" v-if="screenWidth > 640">
    <div class="events__wrapper container">
      <div class="events__titleBlock">
        <div class="events__titleBlock-wrapper">
          <h3 class="events__title">мероприятия</h3>
          <h3 class="events__title events__title--grey">
            <svg v-if="screenWidth > 1686" width="161" height="39" fill="none">
              <path
                fill="#E6E6E6"
                d="m141.797.814-6.632 6.634 7.771 7.772H0v9.38h142.936l-7.771 7.772 6.632 6.633L160.89 19.91 141.797.814Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="130"
              height="32"
              viewBox="0 0 130 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M113.838 0.65625L108.532 5.96275L114.749 12.1805L0.400391 12.1805V19.6846L114.749 19.6846L108.532 25.9023L113.838 31.2088L129.113 15.9325L113.838 0.65625Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth <= 1355"
              width="65"
              height="16"
              viewBox="0 0 65 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.9191 0.324219L54.2662 2.97747L57.3747 6.08632L0.200195 6.08632V9.83839L57.3747 9.83839L54.2662 12.9472L56.9191 15.6005L64.5563 7.96236L56.9191 0.324219Z"
                fill="#E6E6E6"
              />
            </svg>
            kss school
          </h3>
        </div>
        <router-link to="events" class="events__btn">
          читать больше
          <svg v-if="screenWidth > 1686" width="36" height="16" fill="none">
            <path
              fill="#fff"
              d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="29"
            height="13"
            viewBox="0 0 29 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.1657 7.17506C28.4781 6.86264 28.4781 6.35611 28.1657 6.04369L23.0745 0.952521C22.7621 0.640101 22.2556 0.640101 21.9431 0.952521C21.6307 1.26494 21.6307 1.77147 21.9431 2.08389L26.4686 6.60938L21.9431 11.1349C21.6307 11.4473 21.6307 11.9538 21.9431 12.2662C22.2556 12.5786 22.7621 12.5786 23.0745 12.2662L28.1657 7.17506ZM0 7.40938H27.6V5.80937H0V7.40938Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="15"
            height="7"
            viewBox="0 0 15 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0828 4.08362C14.2391 3.92741 14.2391 3.67415 14.0828 3.51794L11.5373 0.972354C11.381 0.816144 11.1278 0.816144 10.9716 0.972354C10.8154 1.12856 10.8154 1.38183 10.9716 1.53804L13.2343 3.80078L10.9716 6.06352C10.8154 6.21973 10.8154 6.473 10.9716 6.62921C11.1278 6.78542 11.381 6.78542 11.5373 6.62921L14.0828 4.08362ZM0 4.20078H13.8V3.40078H0V4.20078Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
      <div class="events__cardsList">
        <div v-for="event in events" :key="event.id">
          <EventCardComponent :event="event" :screenWidth="screenWidth" />
        </div>
      </div>
    </div>
  </section>
  <section class="events" v-if="screenWidth <= 430">
    <div class="events__wrapper container">
      <div class="events__titleBlock">
        <div class="events__titleBlock-wrapper">
          <h3 class="events__title">мероприятия</h3>
          <h3 class="events__title events__title--grey">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="21" viewBox="0 0 27 21" fill="none">
              <path
                d="M15.9891 0L12.3514 3.63823L16.6138 7.90121L0 7.90121V13.0462L16.6138 13.0462L12.3514 17.3092L15.9891 20.9474L26.4616 10.4737L15.9891 0Z"
                fill="#E6E6E6"
              />
            </svg>
            kss school
          </h3>
        </div>
      </div>
      <div class="events__cardsList">
        <EventSliderComponent :events="events" :screenWidth="screenWidth" />
      </div>
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" style="margin-bottom: 50px" />
</template>

<script>
import EventCardComponent from '@/components/EventCardComponent.vue';
import EventSliderComponent from '@/components/EventSliderComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import MediaComponent from '@/components/MediaComponent.vue';
import { mapState } from 'vuex';

export default {
  components: { MediaComponent, LineComponent, EventCardComponent, EventSliderComponent },

  props: {
    screenWidth: Number,
  },

  data() {
    return {
      currentYear: 2024,
      isShow: false,
    };
  },

  computed: {
    ...mapState({
      events: (state) => state.eventCards.cards,
      year2024: (state) => state.media.year2024,
      year2023: (state) => state.media.year2023,
      year2022: (state) => state.media.year2022,
      year2021: (state) => state.media.year2021,
      year2020: (state) => state.media.year2020,
      year2019: (state) => state.media.year2019,
      year2018: (state) => state.media.year2018,
      year2017: (state) => state.media.year2017,
      year2016: (state) => state.media.year2016,
      year2015: (state) => state.media.year2015,
      year2014: (state) => state.media.year2014,
      links: (state) => state.media.links,
    }),
  },

  methods: {
    toggleActive(link) {
      link.active = !link.active;
      this.currentYear = link.title;
      this.updateActiveLinks(link);
      this.isShow = !this.isShow;
    },

    updateActiveLinks(activeLink) {
      this.links.forEach((link) => {
        if (link !== activeLink) {
          link.active = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
