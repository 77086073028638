<template>
  <section class="banner">
    <div class="banner__content container">
      <div class="banner__firstLine">
        <h2 class="banner__title">САМОКАТ</h2>
        <img class="banner__logo" src="@/assets/img/logo.png" alt="logo" />
        <button class="banner__btn ms_booking">ЗАПИСАТЬСЯ НА ТРЕНИРОВКИ</button>
      </div>
      <div class="banner__secondLine">
        <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="140" height="57" fill="none">
          <path
            fill="#E6E6E6"
            d="m111.648.066-9.726 9.727 11.396 11.398H0v13.755h113.318l-11.396 11.397 9.726 9.727 27.998-28.002L111.648.066Z"
          />
        </svg>
        <svg
          v-if="screenWidth >= 1356 && screenWidth <= 1686"
          width="113"
          height="46"
          viewBox="0 0 113 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M90.1151 0.84375L82.3346 8.62528L91.4512 17.743L0.796875 17.743V28.7472L91.4512 28.7472L82.3346 37.8649L90.1151 45.6465L112.514 23.2451L90.1151 0.84375Z"
            fill="#E6E6E6"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth < 1356"
          width="57"
          height="24"
          viewBox="0 0 57 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.0595 0.625L41.1692 4.51576L45.7276 9.07464L0.400391 9.07464V14.5767L45.7276 14.5767L41.1692 19.1356L45.0595 23.0264L56.2588 11.8257L45.0595 0.625Z"
            fill="#E6E6E6"
          />
        </svg>
        <svg v-if="screenWidth < 640" width="81" height="45" fill="none">
          <path
            fill="#E6E6E6"
            d="M58.236.02 50.46 7.83l9.11 9.15H.133v11.044H59.57l-9.11 9.15 7.775 7.81 22.38-22.482L58.236.021Z"
          />
        </svg>
        <h2 class="banner__title banner__title--italic">
          ШКОЛА
          <span class="banner__title--grey">KSS SCHOOL</span>
        </h2>
      </div>
    </div>
    <img
      v-if="screenWidth > 1686 || screenWidth <= 640"
      class="thumb banner__thumb1"
      src="@/assets/img/thumbs1.svg"
      alt="thumb"
    />
    <img class="thumb banner__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <img class="thumb banner__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <img
      v-if="screenWidth > 1686 || screenWidth <= 640"
      class="thumb banner__thumb4"
      src="@/assets/img/thumbs2.svg"
      alt="thumb"
    />
    <img class="thumb banner__thumb5" src="@/assets/img/thumbs2.svg" alt="thumb" />
  </section>
  <section class="topSlider">
    <TopSliderComponent />
  </section>
  <TickerComponent :screenWidth="screenWidth" />

  <section class="aboutScooter">
    <div class="aboutScooter__wrapper container">
      <div class="aboutScooter__firstBlock">
        <div class="aboutScooter__titleBlock">
          <h2 class="aboutScooter__title">САМОКАТНЫЙ СПОРТ</h2>
          <h2 class="aboutScooter__title aboutScooter__title--grey">
            <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="52" height="39" fill="none">
              <path
                fill="#E6E6E6"
                d="m32.797.814-6.632 6.634 7.771 7.772H0v9.38h33.936l-7.771 7.772 6.632 6.633L51.89 19.91 32.797.814Z"
              />
            </svg>
            <svg
              v-if="screenWidth >= 1356 && screenWidth <= 1686"
              width="42"
              height="31"
              viewBox="0 0 42 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.2378 0.03125L20.932 5.33775L27.1489 11.5555L0 11.5555V19.0596L27.1489 19.0596L20.932 25.2773L26.2378 30.5838L41.5122 15.3075L26.2378 0.03125Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg
              v-if="screenWidth >= 640 && screenWidth < 1356"
              width="21"
              height="16"
              viewBox="0 0 21 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1189 0.722656L10.466 3.3759L13.5745 6.48476L0 6.48476V10.2368L13.5745 10.2368L10.466 13.3457L13.1189 15.9989L20.7561 8.3608L13.1189 0.722656Z"
                fill="#E6E6E6"
              />
            </svg>
            <svg v-if="screenWidth < 640" width="29" height="22" fill="none">
              <path
                fill="#E6E6E6"
                d="M18.527.691 14.89 4.33l4.262 4.263H.538v5.145h18.614L14.89 18l3.637 3.638L29 11.165 18.527.691Z"
              />
            </svg>
            НАЧНИ {{ screenWidth > 640 ? 'ТРЕНИРОВАТЬСЯ' : '' }} УЖЕ СЕЙЧАС!
          </h2>
        </div>
        <router-link to="/scooter-sport" class="aboutScooter__readMore">
          ЧИТАТЬ БОЛЬШЕ
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
            <path
              fill="#fff"
              d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="29"
            height="13"
            viewBox="0 0 29 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.568 6.96412C28.8804 6.6517 28.8804 6.14517 28.568 5.83275L23.4769 0.741583C23.1644 0.429164 22.6579 0.429164 22.3455 0.741583C22.0331 1.054 22.0331 1.56053 22.3455 1.87295L26.871 6.39844L22.3455 10.9239C22.0331 11.2363 22.0331 11.7429 22.3455 12.0553C22.6579 12.3677 23.1644 12.3677 23.4769 12.0553L28.568 6.96412ZM0.402344 7.19844H28.0023V5.59844H0.402344V7.19844Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="15"
            height="7"
            viewBox="0 0 15 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.284 3.68128C14.4402 3.52507 14.4402 3.2718 14.284 3.11559L11.7384 0.57001C11.5822 0.413801 11.329 0.413801 11.1727 0.57001C11.0165 0.72622 11.0165 0.979486 11.1727 1.1357L13.4355 3.39844L11.1727 5.66118C11.0165 5.81739 11.0165 6.07065 11.1727 6.22686C11.329 6.38307 11.5822 6.38307 11.7384 6.22686L14.284 3.68128ZM0.201172 3.79844H14.0012V2.99844H0.201172V3.79844Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
      <div class="aboutScooter__secondBlock">
        <article class="aboutScooter__card" v-for="card in cards" :key="card.id">
          <h2 class="aboutScooter__card-title">
            <span>{{ card.id }}</span>
            {{ card.title }}
          </h2>
          <p class="aboutScooter__card-text">{{ card.text }}</p>
        </article>
        <router-link to="/scooter-sport" class="aboutScooter__readMore aboutScooter__readMore-mobile">
          ЧИТАТЬ БОЛЬШЕ
          <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
              fill="#A1D6FF"
            />
          </svg>
        </router-link>
        <img class="thumb aboutScooter__thumb6" src="@/assets/img/thumbs2.svg" alt="thumb" />
        <img class="thumb aboutScooter__thumb7" src="@/assets/img/thumbs2.svg" alt="thumb" />
        <img class="thumb aboutScooter__thumb8" src="@/assets/img/thumbs2.svg" alt="thumb" />
        <img class="thumb aboutScooter__thumb9" src="@/assets/img/thumbs2.svg" alt="thumb" />
      </div>
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" style="margin-bottom: 35px" />
  <TickerComponent :screenWidth="screenWidth" v-if="screenWidth > 640" />
  <section class="growUp">
    <div class="growUp__block1">
      <div class="growUp__block1-titleBlock">
        <h2 class="growUp__block1-title">МЫ РАСТИМ</h2>
        <h2 class="growUp__block1-title growUp__block1-title--blue">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="110" height="38" fill="none">
            <path
              fill="#F1F1F1"
              d="M91.3.094 84.862 6.53l7.542 7.543H0v9.104h92.405l-7.542 7.543 6.436 6.438 18.531-18.533L91.3.094Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="89"
            height="30"
            viewBox="0 0 89 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M73.8443 0.273438L68.6949 5.42353L74.7286 11.458L0.804688 11.458V18.7409L74.7286 18.7409L68.6949 24.7754L73.8443 29.9255L88.6685 15.0995L73.8443 0.273438Z"
              fill="#F1F1F1"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="45"
            height="16"
            viewBox="0 0 45 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.9202 0.832031L34.3455 3.40708L37.3623 6.4243L0.400391 6.4243V10.0658L37.3623 10.0658L34.3455 13.083L36.9202 15.6581L44.3323 8.24504L36.9202 0.832031Z"
              fill="#F1F1F1"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="59" height="21" fill="none">
            <path
              fill="#8BCDFF"
              d="M47.99 0 44.35 3.638l4.263 4.263H.5v5.145h48.114L44.35 17.31l3.638 3.638 10.473-10.473L47.989 0Z"
            />
          </svg>
          ЧЕМПИОНОВ!
        </h2>
      </div>
      <router-link to="/achievements" class="growUp__block1-btn">ДОСТИЖЕНИЯ НАШИХ УЧЕНИКОВ</router-link>
    </div>
    <div class="growUp__block2">
      <span class="growUp__block2-title1">ТРЮКОВОЙ САМОКАТ — ЭТО РИСК ПО СВОЕЙ ПРИРОДЕ</span>
      <p class="growUp__block2-text growUp__block2-text--marginBottom">
        Кто катается, тот знает, что в&nbsp;этой дисциплине каждый трюк&nbsp;&mdash; это победа. Чем сложнее, выше
        и&nbsp;страшнее, тем большая награда оказывается в&nbsp;руках райдера при приземлении.
      </p>
      <p class="growUp__block2-text">
        Мы&nbsp;учим побеждать свои страхи, упорству в&nbsp;достижении целей и&nbsp;главное&nbsp;&mdash; получению
        удовольствия от&nbsp;катания. Тренировки в&nbsp;самокат-школе развивают выносливость, координацию, умение
        владеть инвентарем и&nbsp;собой в экстремальных ситуациях.
      </p>
      <span class="growUp__block2-title2">В школе экстремального самоката мы учим рисковать профессионально.</span>
      <router-link to="/achievements" class="growUp__block2-btn">
        ДОСТИЖЕНИЯ НАШИХ УЧЕНИКОВ
        <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
            fill="#A1D6FF"
          />
        </svg>
      </router-link>
      <img v-if="screenWidth > 640" class="thumb growUp__thumb10" src="@/assets/img/bigThumbs.svg" alt="thumb" />
      <img v-if="screenWidth <= 430" class="thumb growUp__thumb12" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img v-if="screenWidth <= 430" class="thumb growUp__thumb13" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </div>
    <div class="growUp__block3">
      <img v-if="screenWidth > 640" src="@/assets/img/growUp.jpg" alt="growUp" />
      <img v-if="screenWidth <= 430" src="@/assets/img/growUpMobile.webp" alt="growUp" />
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" style="margin-top: 35px" />
  <TickerComponent :screenWidth="screenWidth" v-if="screenWidth > 640" />
  <section class="reasons">
    <div class="reasons__wrapper container">
      <div class="reasons__titleBlock">
        <h2 class="reasons__title">
          почему нужно выбрать
          <span v-if="screenWidth > 640">самокат-школу?</span>
        </h2>
        <h2 class="reasons__title reasons__title--grey" v-if="screenWidth > 640">
          <svg v-if="screenWidth >= 1686" xmlns="http://www.w3.org/2000/svg" width="260" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m240.797.814-6.632 6.634 7.771 7.772H0v9.38h241.936l-7.771 7.772 6.632 6.633L259.89 19.91 240.797.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="209"
            height="31"
            viewBox="0 0 209 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M193.036 0.0390625L187.73 5.34556L193.947 11.5633L0.398438 11.5633V19.0674L193.947 19.0674L187.73 25.2851L193.036 30.5916L208.311 15.3153L193.036 0.0390625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="105"
            height="16"
            viewBox="0 0 105 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M96.5201 0.71875L93.8672 3.372L96.9756 6.48086L0.201172 6.48085V10.2329L96.9756 10.2329L93.8672 13.3418L96.5201 15.995L104.157 8.35689L96.5201 0.71875Z"
              fill="#E6E6E6"
            />
          </svg>
          профессионализм
        </h2>
        <h2 class="reasons__title reasons__title--grey" v-if="screenWidth < 640">
          <svg width="40" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="m29.774.62-3.377 3.378 3.957 3.958H0v4.776h30.354l-3.957 3.958 3.377 3.378 9.723-9.724L29.774.62Z"
            />
          </svg>
          самокат-школу kss?
        </h2>
      </div>
      <div class="reasons__cardsList">
        <div class="reasons__card reasons__card--black">
          <h3 class="reasons__card-title">квалифицированные специалисты</h3>
          <p class="reasons__card-text">
            Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию,
            так&nbsp;и&nbsp;педагогическую. Постоянно совершенствуют свои навыки,&nbsp;ищут новые способы передачи
            своего опыта подрастающему поколению и&nbsp;ко&nbsp;всем находят свой подход.
          </p>
        </div>
        <div class="reasons__card reasons__card--white">
          <h3 class="reasons__card-title reasons__card-title--blue">
            Благодаря этому, процесс обучения всегда поэтапный, наполнен яркими эмоциями и успехами, а результат
            достигается всегда раньше, чем при самостоятельном обучении.
          </h3>
        </div>
        <img class="reasons__img" src="@/assets/img/reasons.webp" alt="reasons" />
      </div>
    </div>
  </section>
  <LineComponent />
  <section class="coaches">
    <div class="coaches__wrapper container">
      <div class="coaches__titleBlock">
        <h3 class="coaches__title">Тренерский</h3>
        <h3 class="coaches__title coaches__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="198" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m178.131.814-6.633 6.634 7.772 7.772H.08v9.38h179.19l-7.772 7.772 6.633 6.633 19.093-19.095L178.131.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="158"
            height="31"
            viewBox="0 0 158 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M142.707 0.03125L137.401 5.33775L143.618 11.5555L0.265625 11.5555V19.0596L143.618 19.0596L137.401 25.2773L142.707 30.5838L157.981 15.3075L142.707 0.03125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="80"
            height="16"
            viewBox="0 0 80 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M71.8515 0.0273438L69.1986 2.68059L72.307 5.78945L0.630859 5.78945V9.54152L72.307 9.54152L69.1986 12.6504L71.8515 15.3036L79.4887 7.66548L71.8515 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="80" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M68.99 0 65.35 3.638l4.263 4.263H.5v5.145h69.114L65.35 17.31l3.638 3.638 10.473-10.473L68.989 0Z"
            />
          </svg>
          состав
        </h3>
      </div>
      <CoachesListSliderComponent v-if="screenWidth > 1686" :screenWidth="screenWidth" />
      <CoachesSliderTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" :screenWidth="screenWidth" />
      <CoachesSliderMobileComponent v-if="screenWidth <= 430" :screenWidth="screenWidth" />
    </div>
  </section>
  <LineComponent />
  <section class="lessonFormats">
    <div class="lessonFormats__wrapper container">
      <div class="lessonFormats__titleBlock">
        <h3 class="lessonFormats__title">форматы занятий</h3>
        <h3 class="lessonFormats__title lessonFormats__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="104" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m84.052.814-6.633 6.634 7.771 7.772H0v9.38h85.19l-7.77 7.772 6.632 6.633 19.093-19.095L84.052.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="32"
            viewBox="0 0 83 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.4444 0.84375L62.1385 6.15025L68.3555 12.368L0.203125 12.368V19.8721L68.3555 19.8721L62.1385 26.0898L67.4444 31.3963L82.7188 16.12L67.4444 0.84375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.2202 0.0273438L31.5673 2.68059L34.6758 5.78945L0.599609 5.78945V9.54152L34.6758 9.54152L31.5673 12.6504L34.2202 15.3036L41.8574 7.66548L34.2202 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="27" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M16.49-.003 12.85 3.635l4.263 4.263H.5v5.145h16.614l-4.263 4.263 3.638 3.639L26.962 10.47 16.489-.003Z"
            />
          </svg>
          в самокат-школе KSS
        </h3>
      </div>
      <LessonFormatCardComponent v-if="screenWidth > 1686" />
      <LessonFormatCardTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" :screenWidth="screenWidth" />
      <LessonFormatCardMobileComponent v-if="screenWidth <= 430" />
    </div>
  </section>
  <LineComponent />
  <section class="schedule" id="schedule">
    <div class="schedule__wrapper container">
      <div class="schedule__titleBlock">
        <h3 class="schedule__title">Расписание</h3>
        <h3 class="schedule__title schedule__title--grey">
          <svg v-if="screenWidth > 1686" width="103" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m83.13.364-6.632 6.633 7.771 7.773H0v9.38h84.27l-7.772 7.772 6.632 6.633 19.094-19.095L83.13.364Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M67.0044 0.4375L61.6986 5.744L67.9155 11.9617L0.5 11.9617V19.4658L67.9155 19.4658L61.6986 25.6836L67.0044 30.9901L82.2788 15.7138L67.0044 0.4375Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="42"
            height="16"
            viewBox="0 0 42 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.053 0.546875L31.4001 3.20012L34.5085 6.30898L0.800781 6.30898V10.061L34.5085 10.061L31.4001 13.1699L34.053 15.8232L41.6902 8.18502L34.053 0.546875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="53.461" height="20.947" fill="none">
            <path
              fill="#E6E6E6"
              d="M42.989 0 39.35 3.638l4.263 4.263H0v5.145h43.614L39.35 17.31l3.638 3.638L53.46 10.474 42.99 0Z"
            />
          </svg>
          KSS PARK {{ screenWidth <= 1686 && screenWidth >= 1356 ? '// М.НАГОРНАЯ' : '' }}
        </h3>
      </div>
      <button v-if="screenWidth > 640" class="schedule__linkToRules" @click="openHoverRules">
        <svg v-if="screenWidth > 1686" width="16" height="16" fill="none">
          <circle cx="7.839" cy="8.144" r="7.839" fill="#0091FF" />
        </svg>
        <svg
          v-if="screenWidth <= 1686 && screenWidth >= 640"
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.93638" cy="3.86216" r="3.1356" fill="#0091FF" />
        </svg>
        Правила посещения парка
      </button>
      <div v-if="screenWidth <= 430" class="schedule__available">
        <span class="schedule__available-yes">доступен для записи</span>
        <span class="schedule__available-no">записи больше нет</span>
        <span class="schedule__available-group">групповая тренировка</span>
      </div>
    </div>
    <div v-if="screenWidth > 640" class="hoverRules__wrapper container">
      <HoverRulesComponent v-show="isShowHoverRules" />
    </div>
    <div class="schedule__table container">
      <!-- <IndividualScheduleComponent v-if="screenWidth > 1686" />
      <IndividualScheduleTabletComponent v-if="screenWidth >= 640 && screenWidth <= 1686" />
      <IndividualScheduleMobileComponent v-if="screenWidth <= 430" /> -->
			<img src="@/assets/img/schedule-home.png" alt="schedule">
    </div>
    <div class="container" v-if="screenWidth <= 430">
      <router-link class="schedule__linkToRulesMobile" to="/rules">
        правила посещения ТРЕНИРОВКИ
        <svg width="43.974" height="12.988" fill="none">
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M0 5.52h42.995v1.95H0V5.52Zm41.617.974L36.79 1.668a.966.966 0 0 1 0-1.378.966.966 0 0 1 1.379 0l5.515 5.515a.966.966 0 0 1 0 1.379l-5.516 5.515a.966.966 0 0 1-1.378 0 .966.966 0 0 1 0-1.38l4.826-4.825Z"
          />
        </svg>
      </router-link>
    </div>
  </section>
  <LineComponent />
  <img
    v-if="screenWidth > 640"
    class="championBanner container"
    src="@/assets/img/championBanner.png"
    alt="Мы растим чемпионов"
  />
  <img
    v-if="screenWidth <= 430"
    class="championBanner container"
    src="@/assets/img/championBannerMobile.webp"
    alt="Мы растим чемпионов"
  />
  <LineComponent />
  <section class="partners">
    <div class="partners__wrapper container">
      <div class="partners__titleBlock">
        <h3 class="partners__title">партнёры</h3>
        <h3 class="partners__title partners__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="61" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="M41.051.814 34.42 7.448l7.771 7.772H.5v9.38h41.69l-7.77 7.772 6.631 6.633L60.145 19.91 41.05.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="49"
            height="31"
            viewBox="0 0 49 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.0428 0.046875L27.737 5.35337L33.9539 11.5711L0.601562 11.5711V19.0752L33.9539 19.0752L27.737 25.2929L33.0428 30.5994L48.3172 15.3232L33.0428 0.046875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="33" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M22.49 0 18.85 3.638l4.263 4.263H0v5.145h23.114L18.85 17.31l3.638 3.638 10.473-10.473L22.489 0Z"
            />
          </svg>
          самокат-школы
        </h3>
      </div>
    </div>
    <PartnersComponent v-if="screenWidth > 1686" />
    <PartnersSliderTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" />
    <PartnersSliderMobileComponent v-if="screenWidth <= 430" />
  </section>
  <LineComponent />
  <section class="feedback">
    <div class="feedback__wrapper container">
      <div class="feedback__titleBlock">
        <h3 class="feedback__title">Отзывы</h3>
        <h3 class="feedback__title feedback__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="45"
            height="32"
            viewBox="0 0 45 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7044 0.640625L24.3986 5.94712L30.6155 12.1648L0 12.1648V19.669L30.6155 19.669L24.3986 25.8867L29.7044 31.1932L44.9788 15.9169L29.7044 0.640625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth < 1356 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          учеников и родителей
        </h3>
      </div>
    </div>
    <FeedbackSliderComponent :screenWidth="screenWidth" />
  </section>
  <LineComponent />
  <section class="questions container">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #57606f" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
</template>

<script>
import CoachesListComponent from '@/components/CoachesListComponent.vue';
import CoachesSliderMobileComponent from '@/components/CoachesSliderMobileComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import LessonFormatCardComponent from '@/components/LessonFormatCardComponent.vue';
import LessonFormatCardMobileComponent from '@/components/LessonFormatCardMobileComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import PartnersComponent from '@/components/PartnersComponent.vue';
import PartnersSliderMobileComponent from '@/components/PartnersSliderMobileComponent.vue';
import CoachesListSliderComponent from '@/components/CoachesListSliderComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import TickerComponent from '@/components/TickerComponent.vue';
import TopSliderComponent from '@/components/TopSliderComponent.vue';
import { mapState, mapMutations } from 'vuex';
import PartnersSliderTabletComponent from '@/components/PartnersSliderTabletComponent.vue';
import LessonFormatCardTabletComponent from '@/components/LessonFormatCardTabletComponent.vue';
import CoachesSliderTabletComponent from '@/components/CoachesSliderTabletComponent.vue';
import IndividualScheduleComponent from '@/components/IndividualScheduleComponent.vue';
import IndividualScheduleTabletComponent from '@/components/IndividualScheduleTabletComponent.vue';
import IndividualScheduleMobileComponent from '@/components/IndividualScheduleMobileComponent.vue';
import HoverRulesComponent from '@/components/HoverRulesComponent.vue';

export default {
  name: 'Home page',
  props: {
    screenWidth: Number,
  },
  components: {
    TopSliderComponent,
    TickerComponent,
    LineComponent,
    CoachesListComponent,
    FeedbackSliderComponent,
    QuestionComponent,
    LessonFormatCardComponent,
    LessonFormatCardMobileComponent,
    LessonFormatCardTabletComponent,
    PartnersComponent,
    PartnersSliderMobileComponent,
    PartnersSliderTabletComponent,
    CoachesSliderMobileComponent,
    CoachesListSliderComponent,
    CoachesSliderTabletComponent,
    IndividualScheduleComponent,
    IndividualScheduleTabletComponent,
    IndividualScheduleMobileComponent,
    HoverRulesComponent,
  },
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://w388672.yclients.com/widgetJS`;
    script.charset = 'UTF-8';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  },

  computed: {
    ...mapState({
      cards: (state) => state.aboutScooterCards.cards,
    }),
    ...mapState(['isShowHoverRules']),
  },

  methods: {
    ...mapMutations(['openHoverRules']),
  },
};
</script>

<style lang="scss" scoped></style>
