import { v4 as uuidv4 } from 'uuid';

const coach = {
  state: {
    coaches: [
      {
        id: uuidv4(),
        name: 'Юра',
        lastName: 'Малина',
        photo: require('@/assets/img/Coaches/akimovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/akimovMainMobile.webp'),
        avatar: require('@/assets/img/Coaches/malina.webp'),
        description: `Юра Малина - легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.
				`,
        achievements: `Юра Малина - легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.

					Благодаря развитию в многочисленных спортивных направлениях Юра привнёс в катание уникальные и узнаваемые элементы.

					В нужной степени изучив и разобрав на детали концепцию катания на экстремальном самокате, занялся преподавательской деятельностью.
					Берет на себя курирование ребят самого разного уровня и подготовки, доводит до требуемого результата, мягко направляя и находя подход к каждому подопечному.
				`,
        education: 'Park, Big Air, Street',
        photos: [
          require('@/assets/img/Coaches/malinaGallary/D8N26jcRqLw.webp'),
          require('@/assets/img/Coaches/malinaGallary/HyUjBDlKKhM.webp'),
          require('@/assets/img/Coaches/malinaGallary/IMG_0665.webp'),
          require('@/assets/img/Coaches/malinaGallary/IMG_0666.webp'),
          require('@/assets/img/Coaches/malinaGallary/IMG_0667.webp'),
          require('@/assets/img/Coaches/malinaGallary/KfVpqcasW9Y.webp'),
          require('@/assets/img/Coaches/malinaGallary/PRGEAp79zlI.webp'),
          require('@/assets/img/Coaches/malinaGallary/SvOVdyWeN9Y.webp'),
          require('@/assets/img/Coaches/malinaGallary/VKJ7HLk9ZcQ.webp'),
          require('@/assets/img/Coaches/malinaGallary/zxFur964NYM.webp'),
        ],
        video: require('@/assets/videos/malina.mp4'),
        skills: 'Катается с 2009 года,',
        path: '/team/akimov',
        idYclients: '',
      },
      {
        id: uuidv4(),
        name: 'Илья',
        lastName: 'Тарабрин',
        photo: require('@/assets/img/Coaches/tarabrinBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/tarabrin.webp'),
        description: `Илья призер и участник многочисленных соревнований, в категории «профессионалы». Участник экстремального шоу ПРОРЫВ, показательных выступлений.`,
        achievements:
          'Илья активно принимает участие в жизни самокат-школы и ежедневно оттачивает свой преподавательский скилл, за счет чего и является одним из самых востребованных тренеров нашей школы.',
        education: 'Park, Big Air ',
        photos: [
          require('@/assets/img/Coaches/tarabrinGallary/DOfQG4DJUrM.webp'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/tarabrin.mp4'),
        skills: 'Катается с 2013 года',
        path: '/team/tarabrin',
        idYclients: '',
      },
      {
        id: uuidv4(),
        name: 'Костя',
        lastName: 'Козлов',
        photo: require('@/assets/img/Coaches/kozlovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/kozlov.webp'),
        description:
          'Начало карьеры Кости было интересным: он начал свой путь с дисциплины ПАРК, где имел много побед и достижений, а сейчас считается одним из лучших СТРИТ райдеров России. У него огромная аудитория фанатов по всему миру, благодаря его трипам по России и творческим фильмам о своем катании. Впервые приняв участие в крупнейших мировых соревнованиях, таких как чемпионат мира в Барселоне Костя начал завоевывать  международное признание  благодаря своей  энергии, невероятной технике катания и участию в международных конкурсах.',
        achievements: [
          '●	Чемпион России 2019 в категории стрит',
          '●	1 место Street Jam Aztek Scooters 2019 ',
          '●	1 место Russian Scooter Day 2019 ',
          '●	2 место back 2 meat 2020 ',
          '●	1 место WinterScooterGames 2020',
          '●	2 место MOSCOW EXTREME WEEK 2021',
        ],
        education: 'Street',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        sponsors: 'KSS, AO Scooters',
        skills: 'Катается с 2013 года',
        path: '/team/kozlov',
        idYclients: 'w1062805',
      },
      {
        id: uuidv4(),
        name: 'Захар',
        lastName: 'Михин',
        photo: require('@/assets/img/Coaches/mihinBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/empty.webp'),
        description: `Захар - легенда паркого катания прошлых лет, но сейчас он активно начал познавать стрит направление и уже полноценно влился в эту новую для него дисциплину. Захар имеет творческий и экспериментальный подход к катанию, ищет новые локации и фантазирует над трюками. В его катании преобладает много сильных парковых комбинаций, которые он запросто исполняет на улицах разных городов. Благодаря своему большому видео он смог стать участником международного соревнования Dissidence Coalition 2021, а также пройти в ТОП-6 в международной премии КАРДО. 
					
					Находит подход к самым юным райдерам самокатного спорта и ведет их от "нуля" до желаемого результата.`,
        achievements: ['●	Финал в Международной премии КАРДО', '●	1 место Moscow Scooter Games 2017'],
        education:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/mihin.mp4'),
        sponsors: 'Kickscootershop ',
        skills: 'Катается с 2013 года',
        path: '/team/mihin',
        idYclients: '',
      },
      {
        id: uuidv4(),
        name: 'Дмитрий',
        lastName: 'Фазолов',
        photo: require('@/assets/img/Coaches/fazolovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/fazolov.webp'),
        description: `Дима - сильнейший райдер России с большим количеством фанатов по всему миру. Он является чемпионом России 2019 и 2021 года, принимал участие на чемпионате мира в Барселоне, ежегодный участник всероссийского шоу "Прорыв", был приглашенным гостем в телешоу "Вечерний Ургант" и многократный победитель различных соревнований. Катание Димы отличается большим количеством различных кручений телом или сальто. Также Дима представляет зарубежный бренд самокатов Blunt и отечественный энергетик Gorilla Energy.

					В его арсенале есть трюки, которые делают единицы. Дима знает как из "дворового попрыгунчика" стать спортсменом мирового уровня, и конечно, этим он и делиться на своих тренировках.
					`,
        achievements: [
          '● Двукратный Чемпион России 2018/2021 года',
          '● MOSCOW SCOOTER GAMES 2019 1 место big air',
          '● Место действия BIG AIR 2019 3 место',
          '● INGRESS scooter jam 2019 3 место',
          '● Открытое первенство Москвы 2019 1 место',
          '● BIG AIR | ВДНХ 2020 4 место',
          '● Фестиваль Прорыв 2020 1 место',
        ],
        education: 'Park, Big Air, Street ',
        photos: [
          require('@/assets/img/Coaches/fazolovGallary/aYSS-LRzexo.webp'),
          require('@/assets/img/Coaches/fazolovGallary/ghZ8SgcmzHY.webp'),
          require('@/assets/img/Coaches/fazolovGallary/gu5RxUgpf7M.webp'),
        ],
        video: require('@/assets/videos/fazolov.mp4'),
        sponsors: 'Kickscootershop, Gorilla Energy, BidloBikes, Blunt Scooter',
        skills: 'Катается с 2014 года',
        path: '/team/fazolov',
        idYclients: '',
      },
      {
        id: uuidv4(),
        name: 'Стас',
        lastName: 'Басанский',
        photo: require('@/assets/img/Coaches/emptyTeam.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/basanskiy.webp'),
        description: `Стас является одним из лучших парковых райдеров России, за его плечами многочисленные победы и достижения. Бронзовый призер чемпионата Москвы и России, участник всероссийского шоу "Прорыв". В его катании преобладает разнообразие трюков и сальто на высоких амплитудах. Стас силен трюками, которые смогут повторить единицы в мире. 
					Стас тренирует райдеров разного уровня подготовки, легко находит общий язык с ребятами любого возраста, мягко направляя к намеченной цели. Он учит побеждать страх и работать на самый высокий результат.
					`,
        achievements: [
          '●	3 место - Экстремальное шоу «Прорыв» 2021;',
          '●	3 место -Чемпионат России 2018;',
          '●	2 место - Winter Scooter Games 2019;',
          '●	3 место- Чемпионат России 2019;',
          '●	3 место - BIGAIR Чемпионат Москвы 2020;',
          '●	3 место - Чемпионат России 2021;',
          '●	1 место - Ночь Спорта 2021;',
          '●	2 место- Winter Scooter Games 2021;',
          '●	3 место- AGtour 2021;',
          '●	1 место - "Кубок Москвы" 2022;',
          '●	2 место "Уличная классика" в Казани 2022;',
          '●	2 место "Уличная классика" в Питере 2022;',
          '●	1 место - Чемпионат России 2022.',
        ],
        education: 'Park, Big Air',
        photos: [
          require('@/assets/img/Coaches/basanskyGallary/KMSqYMxbyAY.webp'),
          require('@/assets/img/Coaches/basanskyGallary/nNBxzHLF1T.webp'),
          require('@/assets/img/Coaches/basanskyGallary/pehy9gOD0hQ.webp'),
          require('@/assets/img/Coaches/basanskyGallary/xHRJd1z0jVg.webp'),
        ],
        video: require('@/assets/videos/basanskiy.mp4'),
        sponsors: 'Kickscootershop',
        skills: 'Катается с 2015 года',
        path: '/team/basanskii',
        idYclients: 'w1073215',
      },
      {
        id: uuidv4(),
        name: 'Иван',
        lastName: 'Соловьев',
        photo: require('@/assets/img/Coaches/emptyTeam.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/empty.webp'),
        description:
          'Иван Соловьёв - один из лучших про-райдеров России с 10 летним стажем катания. Призёр и победитель множества российских соревнований, участник команды"Freestylebikeshow". Ваня тренирует ребят любого уровня и возраста уже более 5 лет, имеет индивидуальный подход и упражнения к каждому обучаемому в соответствии с его возможностями и уровнем катания.',
        achievements:
          'Иван Соловьёв - один из лучших про-райдеров России с 10 летним стажем катания. Призёр и победитель множества российских соревнований, участник команды"Freestylebikeshow". Ваня тренирует ребят любого уровня и возраста уже более 5 лет, имеет индивидуальный подход и упражнения к каждому обучаемому в соответствии с его возможностями и уровнем катания.',
        education: 'Park, Street ',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        skills: 'Катается с 2013 года ',
        path: '/team/soloviev',
        idYclients: 'w1073212',
      },
      {
        id: uuidv4(),
        name: 'Артём',
        lastName: 'Кравченко',
        photo: require('@/assets/img/Coaches/kravchenkoBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/kravchenko.webp'),
        description: `Артем является Чемпионом России 2022 года в дисциплине BIG AIR и одним из сильнейших парковых райдеров России. За плечами Тёмы 9 лет катания, участие и победа во всероссийском фестивале "Прорыв", поддержка зарубежного бренда Drone и огромная коллекция эксклюзивных трюков.

					У Артема большой преподавательский опыт - он быстро находит сильные и слабые стороны ученика, и как никто другой знающий о тщательной подготовке к соревнованиям, сможет грамотно составить стабильную, эффективную и зрелищную попытку, использовав максимум возможностей подопечного.
					`,
        achievements: [
          '●	Best trick - Scooter Fest 2022;',
          '●	Кубок Москвы 2022 2 место;',
          '●	Best trick - Чемпионат России 2021;',
          '●	Контест уличная классика (Казань) - 3 место 2022;',
          '●	Контест уличная классика (Санкт-Петербург) - 3 место 2022;',
          '●	Чемпион Москвы 2020 в дисциплине Big Air;',
          '●	1 место "Winter scooter games" 2019/2021;',
          '●	1 место на Всероссийском фестивале "Прорыв" 2021;',
          '●	2 место на Всероссийском фестивале "Прорыв" 2020;',
          '●	2 место "Moscow Extreme Week" 2020.',
        ],
        education: 'Park, Big Air ',
        photos: [
          require('@/assets/img/Coaches/kravchenkoGallary/_awgIC_Qh_g.webp'),
          require('@/assets/img/Coaches/kravchenkoGallary/3aLujTohPkc.webp'),
          require('@/assets/img/Coaches/kravchenkoGallary/5EkPaPMfTGI.webp'),
          require('@/assets/img/Coaches/kravchenkoGallary/56wveXqQldA.webp'),
          require('@/assets/img/Coaches/kravchenkoGallary/SX1rJnH1iLg.webp'),
          require('@/assets/img/Coaches/kravchenkoGallary/w19p0Oo7BSg.webp'),
        ],
        video: require('@/assets/videos/kravchenko.mp4'),
        sponsors: 'Kickscootershop, Drone',
        skills: 'Катается с 2016 года ',
        path: '/team/kravchenko',
        idYclients: 'w1073189',
      },
      {
        id: uuidv4(),
        name: 'Даня',
        lastName: 'Попелнуха',
        photo: require('@/assets/img/Coaches/popelnuhaBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/popelnuha.webp'),
        description:
          'Данила - спортсмен Федерации Самокатного спорта, ежегодный участник Чемпионата России, где показывал высокий уровень катания и профессионализма. Участник экстрим-шоу "Прорыв" 2020г. и постоянный участник показательных выступлений в дисциплине BigAir. В 2021г. завоевал титул чемпиона Пушкинского округа МО. Даня является тренером с 6 летним стажем, легко находит общий язык с учениками любого возраста и уровня подготовки.',
        achievements: [
          '● 4 место на Чемпионате Москвы 2019 в дисциплине Park;',
          '● 7 место на “Moscow Scooter Games” 2020г. в дисциплине BigAir;',
          '● 1 место на соревнованиях в честь открытия скейт-парка г.Покров 2019/2020г.',
        ],
        education: 'Park',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/popelnuha.mp4'),
        skills: 'Катается с 2014 года',
        path: '/team/popelnuha',
        idYclients: 'w1053397',
      },
      {
        id: uuidv4(),
        name: 'Альберт',
        lastName: 'Волков',
        photo: require('@/assets/img/Coaches/volkovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/volkov.webp'),
        description:
          'Большую часть карьеры провёл в Краснодаре, где учился и тренировал свои навыки владения трюковым самокатом. Тренерский стаж - 4,5 года, в течение которых Альберт выработал для себя основную концепцию тренировок - индивидуальный подход к каждому ученику, работа на результат, дружеская атмосфера на тренировках со 100% отдачей.',
        achievements: [
          '●	Участник Чемпионата России 2019, 2021, 2022, 2023 года',
          '●	Открытое первенство Москвы 2019 - 7 место, где и прошел квалификацию, вошел в 10 лучших любителей и получил доступ к участию на Чемпионате России',
        ],
        education: 'Park',
        photos: [
          require('@/assets/img/Coaches/volkovGallary/BAP0umGKtM0.webp'),
          require('@/assets/img/Coaches/volkovGallary/IaqEfAAkdsc.webp'),
          require('@/assets/img/Coaches/volkovGallary/y2EZY77SFlM.webp'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/volkov.mp4'),
        skills: 'Катается с 2015 года',
        path: '/team/volkov',
        idYclients: 'w1059522',
      },
      {
        id: uuidv4(),
        name: 'Саша',
        lastName: 'Хохлов',
        photo: require('@/assets/img/Coaches/hohlovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/hohlov.webp'),
        description:
          'Саша - представитель стрит направления Москвы, один из лучших стрит райдеров России. Санек является серебряным призером чемпионата России, а также многократным призером стрит соревнований Москвы и области. Санька можно назвать королем скольжений. Его умение брать перилы любой сложности можно назвать отдельным видом искусства. Саша очень терпеливый и понимающий и всегда настроен на то, чтобы помочь ученикам преодолеть свои страхи и сомнения.',
        achievements: [
          '●	1 место кубок Москвы 2021',
          '●	2 место на чемпионате России 2019, 2021',
          '●	1 место PEROVO STREET 2019',
          '●	2 место Russian Scooter Day 2019',
        ],
        education: 'Street',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/hohlov.mp4'),
        skills: 'Катается с 2014 года',
        path: '/team/hohlov',
        idYclients: 'w1059528',
      },
      {
        id: uuidv4(),
        name: 'Никита',
        lastName: 'Чильчагов',
        photo: require('@/assets/img/Coaches/chilchagovBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/chilchagov.webp'),
        description:
          'Никита - основатель самокат движения в Челябинске. Силен как в сложных скольжениях, так и в высоких полетах и вращениях. Никита умеет объяснять сложные концепции простым и понятным языком, а также задавать дружескую и легкую атмосферу на тренировках.',
        achievements: [
          '●	Победитель отбора всемирной премии КАРДО',
          '●	1 место RCC 2023',
          '●	3 место RCC 2022',
          '●	2 место Russian Scooter Day 2019',
          '●	Чемпион Челябинска 2020/2021/2022 г.',
        ],
        education: 'Street',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        skills: 'Катается с 2014 года',
        path: '/team/chilchagov',
        idYclients: 'n1106421',
      },
      {
        id: uuidv4(),
        name: 'Саша',
        lastName: 'Мананников',
        photo: require('@/assets/img/Coaches/emptyTeam.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/empty.webp'),
        description:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        achievements:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        education:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        skills: 'Более 100 лет, тренировал того-то, того-то,',
        path: '/team/manannikov',
        idYclients: 'n1106424',
      },
      {
        id: uuidv4(),
        name: 'Артём',
        lastName: 'Кочин',
        photo: require('@/assets/img/Coaches/kochinBig.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/kochin.webp'),
        description:
          'Артём - профессиональный райдер Федерации Самакатного спорта, участник экстрим- шоу "Прорыв", ежегодный участник Чемпионата России по трюковому самокату. У Артема большой опыт в тренерской деятельности, он умеет анализировать результаты своих учеников и определять, какие методы и подходы работают лучше всего.',
        achievements: [
          '● 4 место на соревнованиях в г. Пушкино 2020г.',
          '● 1 место на соревнованиях в г.Ивантеевке 2018.',
          '● 2 место на соревнованиях в честь открытия скейт-парка г.Покров 2019г.',
        ],
        education: 'Park',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        skills: 'Катается с 2015 года',
        path: '/team/kochin',
        idYclients: 'w388672',
      },
      {
        id: uuidv4(),
        name: 'Новый',
        lastName: 'Тренер',
        photo: require('@/assets/img/Coaches/emptyTeam.webp'),
        photoMobile: require('@/assets/img/Coaches/emptyTeam.webp'),
        avatar: require('@/assets/img/Coaches/empty.webp'),
        description:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        achievements:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        education:
          'Легенда отечественного самоката и главный тренер KSS SCHOOL, один из основоположников дисциплины, объединяющей в себе профессиональное владение самокатом как в условиях амплитуды парка, так перил и граней в стриту.',
        photos: [
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
          require('@/assets/img/emptyPhoto.png'),
        ],
        video: require('@/assets/videos/emptyVideo.webp'),
        skills: 'Более 100 лет, тренировал того-то, того-то,',
        path: '/team/new',
        idYclients: '',
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default coach;
