import { v4 as uuidv4 } from 'uuid';

const trainingVideo = {
	state: {
		videos: [
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: require('@/assets/videos/video1.mp4'),
				class: 'video-new',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
			{
				id: uuidv4(),
				title: 'Как сделать трюк хендплент на самокате?',
				description: '| Самокат школа KSS | HOW TO HANDPLANT',
				img: require('@/assets/img/trainingPoster.webp'),
				src: '#',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default trainingVideo;
