<template>
	<swiper :navigation="true" :modules="modules" :loop="true" class="mySwiper">
		<swiper-slide v-for="(slide, index) in topSlides" :key="index"><img :src="slide.img" alt="Banner" /></swiper-slide>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		return {
			modules: [Navigation],
		};
	},

	computed: {
		...mapState({
			topSlides: (state) => state.topBanner.topSlides,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
