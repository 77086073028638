<template>
	<ul class="mobilePrice-list">
		<li class="mobilePrice-details" v-for="(item, index) in price" :key="item.id">
			<div class="mobilePrice-details__wrapper">
				<svg class="mobilePrice-tippy" width="18" height="18" fill="none">
					<path
						fill="#0091FF"
						d="M8.075 13.458h1.794V8.075H8.075v5.383Zm.897-7.178a.869.869 0 0 0 .64-.258.865.865 0 0 0 .257-.639.869.869 0 0 0-.258-.64.865.865 0 0 0-.639-.257.869.869 0 0 0-.64.258.865.865 0 0 0-.257.64c0 .253.086.467.258.639a.865.865 0 0 0 .639.257Zm0 11.664a8.732 8.732 0 0 1-3.5-.707 9.077 9.077 0 0 1-2.848-1.917A9.055 9.055 0 0 1 .707 12.47 8.753 8.753 0 0 1 0 8.972c0-1.241.236-2.407.707-3.5a9.075 9.075 0 0 1 1.917-2.848A9.054 9.054 0 0 1 5.473.707 8.752 8.752 0 0 1 8.972 0c1.241 0 2.408.236 3.5.707a9.074 9.074 0 0 1 2.848 1.917 9.068 9.068 0 0 1 1.918 2.849 8.721 8.721 0 0 1 .706 3.499 8.732 8.732 0 0 1-.707 3.5 9.076 9.076 0 0 1-1.917 2.848 9.069 9.069 0 0 1-2.849 1.918 8.722 8.722 0 0 1-3.499.706Z"
					/>
				</svg>
				<div>
					<span class="mobilePrice-title">{{ item.title }}</span>
					<span class="mobilePrice-quantityMobile">{{ item.quantityMobile }}</span>
				</div>
				<span class="mobilePrice-currentPrice">{{ item.currentPrice }} ₽</span>
			</div>
			<button class="mobilePrice-btn ms_booking">{{item.title.toLowerCase().includes('абонемент') ? 'приобрести' : 'записаться'}}</button>
			<LineComponent v-if="index !== price.length - 1" />
		</li>
		<span>*Срок действия абонемента 30 дней с момента активации</span>
	</ul>
</template>

<script>
import LineComponent from './LineComponent.vue';

export default {
	props: {
		price: Array,
	},
	components: { LineComponent },

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
};
</script>

<style lang="scss" scoped></style>
