<template>
  <NavigationComp />
  <main class="main"><RouterView :screenWidth="screenWidth" /></main>
  <FooterComponent />
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
import NavigationComp from './components/NavigationComp.vue';
import HomePage from './pages/HomePage.vue';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: { NavigationComp, HomePage, FooterComponent },

  computed: {
    ...mapState(['screenWidth']),
  },
  created() {
    this.updateScreenWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      this.updateWidth(window.innerWidth);
    });
  },
  methods: {
    ...mapMutations(['updateScreenWidth']),
    ...mapActions(['updateWidth']),
  },
  watch: {
    screenWidth(newWidth) {
      this.updateWidth(newWidth);
      console.log(newWidth);
    },
  },
};
</script>
