<template>
  <section class="contactsPage" v-if="screenWidth > 640">
    <img class="contactsPage__bg" v-if="screenWidth > 1686" src="@/assets/img/contactBG.webp" alt="Background" />
    <img
      class="contactsPage__bg"
      v-if="screenWidth >= 640 && screenWidth <= 1686"
      src="@/assets/img/contactBGTablet.svg"
      alt="Background"
    />

    <div class="contactsPage__wrapper container">
      <div class="contactsPage__titleBlock">
        <h1 class="contactsPage__title">КОНТАКТЫ</h1>
        <h2 class="contactsPage__title contactsPage__title--grey">
          <svg v-if="screenWidth > 1686" width="129" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m85.927 0-14.82 14.822 17.365 17.366H0v20.96h88.472L71.107 70.515l14.82 14.822 42.663-42.669L85.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="104"
            height="69"
            viewBox="0 0 104 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M69.14 0.195312L57.2842 12.0526L71.1759 25.9461L0.398438 25.9461V42.714L71.1759 42.714L57.2842 56.6074L69.14 68.4648L103.271 34.33L69.14 0.195312Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="52"
            height="35"
            viewBox="0 0 52 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.57 0.597656L28.6421 6.52632L35.5879 13.473L0.199219 13.473V21.857L35.5879 21.857L28.6421 28.8037L34.57 34.7324L51.6353 17.665L34.57 0.597656Z"
              fill="#E6E6E6"
            />
          </svg>
          KSS SCHOOL
        </h2>
        <div class="contactsPage__social">
          <a :href="social.url" v-html="social.svg" v-for="social in socials" :key="social.id"></a>
        </div>
        <div class="contactsPage__addressBlock">
          <span class="contactsPage__address-subtitle">
            групповые и индивидуальные тренировки на базе крытого скейт-парка KSS PARK
          </span>
          <h2 class="contactsPage__address">Москва, Электролитный проезд 3с19</h2>
          <span class="contactsPage__tel-subtitle">Телефон для записи и вопросов</span>
          <h2 class="contactsPage__tel">8 (800) 250-50-06</h2>
        </div>
      </div>

      <div class="contactsPage__map">
        <YandexMap :coordinates="baseCoordinates" :zoom="zoom" :controls="controls">
          <YandexMarker :coordinates="baseCoordinates" />
        </YandexMap>
      </div>

      <img class="thumb contactsPage__thumb1" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb2" src="@/assets/img/thumbs1.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </div>
  </section>

  <section class="contactsPage" v-if="screenWidth <= 430">
    <img class="contactsPage__bg" src="@/assets/img/contactBG.webp" alt="Background" />
    <div class="contactsPage__wrapper container">
      <div class="contactsPage__titleBlock">
        <h1 class="contactsPage__title">КОНТАКТЫ</h1>
        <h2 class="contactsPage__title contactsPage__title--grey">
          <svg width="55" height="36" fill="none">
            <path
              fill="#E6E6E6"
              d="m36.486.665-6.095 6.096 7.142 7.144H0v8.62h37.533l-7.142 7.144 6.095 6.096 17.548-17.55L36.486.665Z"
            />
          </svg>
          KSS SCHOOL
        </h2>
      </div>

      <img class="thumb contactsPage__thumb1" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb2" src="@/assets/img/thumbs1.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb contactsPage__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </div>
  </section>
  <img v-if="screenWidth <= 430" class="contactsPage__mainImg" src="@/assets/img/contacts.webp" alt="Contacts banner" />
  <LineComponent v-if="screenWidth <= 430" style="width: #d4d4d4" />
  <div v-if="screenWidth <= 430" class="contactsPage__addressBlock container">
    <span class="contactsPage__address-subtitle">
      групповые и индивидуальные тренировки на базе крытого скейт-парка KSS PARK
    </span>
    <h2 class="contactsPage__address">Москва, Электролитный проезд 3с19</h2>
  </div>
  <div v-if="screenWidth <= 430" class="contactsPage__map container">
    <YandexMap :coordinates="baseCoordinates" :zoom="zoom" :controls="controls">
      <YandexMarker :coordinates="baseCoordinates" />
    </YandexMap>
  </div>
  <LineComponent v-if="screenWidth <= 430" style="width: #d4d4d4" />
  <div v-if="screenWidth <= 430" class="contactsPage__addressBlock container">
    <span class="contactsPage__tel-subtitle">Телефон для записи и вопросов</span>
    <h2 class="contactsPage__tel">8 (800) 250-50-06</h2>
  </div>
  <div v-if="screenWidth <= 430" class="contactsPage__social container">
    <a :href="social.url" v-html="social.svg" v-for="social in socials" :key="social.id"></a>
  </div>
  <LineComponent v-if="screenWidth <= 430" style="width: #d4d4d4" />

  <section class="partners contactsPage__partners--padding">
    <div class="partners__wrapper container">
      <div class="contactsPage__partners contactsPage__partners--center partners__titleBlock">
        <h3 class="partners__title">партнёры</h3>
        <h3 class="partners__title partners__title--grey">
          <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="61" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="M41.051.814 34.42 7.448l7.771 7.772H.5v9.38h41.69l-7.77 7.772 6.631 6.633L60.145 19.91 41.05.814Z"
            />
          </svg>
					<svg v-if="screenWidth <= 1686 && screenWidth >= 1356" width="49" height="32" viewBox="0 0 49 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.0408 0.648438L27.735 5.95493L33.952 12.1726L0.599609 12.1726V19.6768L33.952 19.6768L27.735 25.8945L33.0408 31.201L48.3153 15.9247L33.0408 0.648438Z" fill="#E6E6E6" />
</svg>
          <svg
            v-if="screenWidth <= 1355 && screenWidth >= 640"
            width="25"
            height="16"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0214 0.0273438L14.3685 2.68059L17.477 5.78945L0.800781 5.78945V9.54152L17.477 9.54152L14.3685 12.6504L17.0214 15.3036L24.6586 7.66548L17.0214 0.0273438Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="33" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M22.49 0 18.85 3.638l4.263 4.263H0v5.145h23.114L18.85 17.31l3.638 3.638 10.473-10.473L22.489 0Z"
            />
          </svg>
          KSS SCHOOL
        </h3>
      </div>
    </div>
    <PartnersComponent v-if="screenWidth > 1686" />
    <PartnersSliderTabletComponent v-if="screenWidth <= 1686 && screenWidth >= 640" />
    <PartnersSliderMobileComponent v-if="screenWidth <= 430" />
  </section>
  <LineComponent v-if="screenWidth <= 430" style="color: #d4d4d4; margin-bottom: 30px" />
  <img v-if="screenWidth > 640" class="contactsPage__mainImg" src="@/assets/img/contacts.webp" alt="Contacts banner" />
</template>

<script>
import { YandexMap, YandexMarker } from 'vue-yandex-maps';
import { ref, computed } from 'vue';
import LineComponent from '@/components/LineComponent.vue';
import PartnersComponent from '@/components/PartnersComponent.vue';
import { mapState } from 'vuex';
import PartnersSliderMobileComponent from '@/components/PartnersSliderMobileComponent.vue';
import PartnersSliderTabletComponent from '@/components/PartnersSliderTabletComponent.vue';

export default {
  components: {
    LineComponent,
    PartnersComponent,
    PartnersSliderMobileComponent,
    PartnersSliderTabletComponent,
  },

  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      socials: (state) => state.footerNavigation.socialsBlack,
    }),
  },

  setup() {
    const controls = [];
    const zoom = 17;
    const baseCoordinates = ref([55.677241, 37.620144]);

    return {
      baseCoordinates,
      controls,
      zoom,
    };
  },
};
</script>

<style lang="scss" scoped></style>
