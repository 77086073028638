<template>
	<div class="educationProgram__stepCard-wrapper">
		<article class="educationProgram__stepCard" v-for="stepCard in educationStepCards" :key="stepCard.id">
			<div class="educationProgram__stepCard-titleBox">
				<div class="educationProgram__stepCard-number">{{ stepCard.id }}</div>
				<h2 class="educationProgram__stepCard-title">{{ stepCard.title }}</h2>
			</div>
			<p class="educationProgram__stepCard-text">{{ stepCard.text }}</p>
		</article>
		<img v-if="screenWidth <= 430" class="thumb stepCard__thumb1" src="@/assets/img/thumbs1.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb stepCard__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb stepCard__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb stepCard__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		screenWidth: Number,
	},

	computed: {
		...mapState({
			educationStepCards: (state) => state.educationStepCards.cards,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
