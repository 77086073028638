const eventCards = {
	state: {
		cards: [
			{
				id: 1,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 2,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
			{
				id: 3,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 4,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
		],
		allEventCards: [
			{
				id: 1,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 2,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
			{
				id: 3,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 4,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
			{
				id: 5,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 6,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
		],
		diaryPageEvents: [
			{
				id: 1,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event1.webp'),
				date: '12 мая 2023',
			},
			{
				id: 2,
				url: '/diary',
				title: 'Соревнования "Зачетная неделя 2022"',
				text: 'Подвели итоги полугодия в KSS SCHOOL',
				img: require('@/assets/img/Events/event2.webp'),
				date: '12 мая 2023',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default eventCards;
