<template>
	<table>
		<thead>
			<tr>
				<td>&nbsp;</td>
				<th>ПН</th>
				<th>ВТ</th>
				<th>СР</th>
				<th>ЧТ</th>
				<th>ПТ</th>
				<th>СБ</th>
				<th>ВС</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>10:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 1)" v-on:mouseleave="cellLeave(0, 1)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][1].groupTitle }}
					</button>
				</td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 2)" v-on:mouseleave="cellLeave(0, 2)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][2].groupTitle }}
					</button>
				</td>
			</tr>
			<tr>
				<th>11:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>12:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 3)" v-on:mouseleave="cellLeave(0, 3)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][3].groupTitle }}
					</button>
				</td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 4)" v-on:mouseleave="cellLeave(0, 4)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][4].groupTitle }}
					</button>
				</td>
			</tr>
			<tr>
				<th>13:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>14:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 5)" v-on:mouseleave="cellLeave(0, 5)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][5].groupTitle }}
					</button>
				</td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 6)" v-on:mouseleave="cellLeave(0, 6)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][6].groupTitle }}
					</button>
				</td>
			</tr>
			<tr>
				<th>15:00</th>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>16:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 7)" v-on:mouseleave="cellLeave(0, 7)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][7].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 8)" v-on:mouseleave="cellLeave(0, 8)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][8].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 9)" v-on:mouseleave="cellLeave(0, 9)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][9].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>17:00</th>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>

				<td><button class="ms_booking individualSchedule__btn"></button></td>

				<td><button class="ms_booking individualSchedule__btn"></button></td>

				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>18:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 10)" v-on:mouseleave="cellLeave(0, 10)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][10].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 11)" v-on:mouseleave="cellLeave(0, 11)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][11].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td rowspan="2" v-on:mouseover="cellHover(0, 12)" v-on:mouseleave="cellLeave(0, 12)">
					<button class="ms_booking individualSchedule__groupBtn">
						{{ cells[0][12].groupTitle }}
					</button>
				</td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>19:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>

				<td><button class="ms_booking individualSchedule__btn"></button></td>

				<td><button class="ms_booking individualSchedule__btn"></button></td>

				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
			</tr>
			<tr>
				<th>20:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
			<tr>
				<th>21:00</th>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn individualSchedule__unavailableBtn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
				<td><button class="ms_booking individualSchedule__btn"></button></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	data() {
		return {
			cells: [
				[
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
					{ groupTitle: 'Групповая тренировка' },
				],
			],
		};
	},

	methods: {
		cellHover(row, col) {
			this.cells[row][col].groupTitle = 'Записаться';
		},
		cellLeave(row, col) {
			this.cells[row][col].groupTitle = 'Групповая тренировка';
		},
	},

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 3px;
	margin-bottom: 30px;
	thead {
		td {
			background: #f6f6f6;
			width: 38px;
			height: 43px;
			text-align: center;
		}

		th {
			background: #252c37;
			width: 38px;
			height: 43px;
			color: #fff;
			font-size: 15px;
			line-height: 20px;
			padding: 0;
			text-align: center;
			vertical-align: middle;
		}
	}

	tbody {
		th {
			background: #252c37;
			color: #fff;
			padding: 0;
			font-size: 15px;
			text-align: center;
			vertical-align: middle;
			line-height: 20px;
		}

		td {
			width: 38px;
			height: 43px;
			padding: 0;
			font-size: 0;
			background: #0091ff;

			font-style: italic;
			font-weight: 600;
			line-height: 0;
			text-transform: uppercase;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			

			&:hover {
				.individualSchedule__groupBtn {
					color: #fff;
				}

				background: #252c37;
			}
		}

		td[rowspan] {
			padding: 0;
			color: #d1d1d1;
			background: #f1f1f1;

			text-align: center;
			vertical-align: middle;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			&:hover {
				color: #fff;
				background: #252c37;
			}
		}
	}

	.individualSchedule__btn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #fff;
		width: 100%;
		height: 100%;
	}
	.individualSchedule__groupBtn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #d1d1d1;
		width: 100%;
		height: 100%;
	}
	.individualSchedule__unavailableBtn {
		border: none;
		outline: none;
		background: #868686;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}
</style>
