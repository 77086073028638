<template>
  <section class="questionPhone container">
    <h2 class="questionPhone__title">остались вопросы?</h2>
    <h2 class="questionPhone__title questionPhone__title--blue">
      <svg v-if="screenWidth > 1686" width="84" height="59" fill="none">
        <path
          fill="#70C1FF"
          d="m55.474.5-9.909 10.16 11.61 11.906H0v14.368h57.176L45.566 48.84 55.473 59 84 29.75 55.474.5Z"
        />
      </svg>
      <svg
        v-if="screenWidth >= 1356 && screenWidth <= 1686"
        width="68"
        height="48"
        viewBox="0 0 68 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.7779 0.789062L36.8508 8.91748L46.1391 18.4417L0.398438 18.4417V29.9364L46.1391 29.9364L36.8508 39.4606L44.7779 47.5891L67.5984 24.1891L44.7779 0.789062Z"
          fill="#70C1FF"
        />
      </svg>
      <svg
        v-if="screenWidth >= 640 && screenWidth < 1356"
        width="34"
        height="24"
        viewBox="0 0 34 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.3889 0.00390625L18.4254 4.06812L23.0696 8.83022L0.199219 8.83022V14.5776L23.0696 14.5776L18.4254 19.3397L22.3889 23.4039L33.7992 11.7039L22.3889 0.00390625Z"
          fill="#70C1FF"
        />
      </svg>
      <svg v-if="screenWidth <= 430" width="27" height="20" fill="none">
        <path
          fill="#70C1FF"
          d="m17.536.863-3.132 3.212 3.67 3.763H0v4.542h18.074l-3.67 3.764 3.132 3.212 9.017-9.247L17.536.863Z"
        />
      </svg>
      <a href="tel:88002505006">8 (800) 250-50-06</a>
    </h2>
  </section>
</template>

<script>
export default {
  props: {
    screenWidth: Number,
  },
};
</script>

<style lang="scss" scoped>
.questionPhone {
  padding: 70px 0 130px 0;

  &__title {
    color: #252c37;
    font-size: 99px;
    font-style: normal;
    font-weight: 900;
    line-height: 99px;
    text-transform: uppercase;

    &--blue {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        color: #70c1ff;
        font-style: italic;
      }
    }
  }
}

@media (max-width: 1686px) and (min-width: 1356px) {
  .questionPhone {
    padding: 70px 0 100px 0;

    &__title {
      font-size: 79px;
      line-height: 79px;
    }
  }
}

@media (max-width: 1355px) and (min-width: 640px) {
  .questionPhone {
    padding: 30px 0 50px 0;

    &__title {
      font-size: 39px;
      line-height: 39px;

      &--blue {
        gap: 10px;
      }
    }
  }
}

@media (max-width: 430px) {
  .questionPhone {
    padding: 10px 0 130px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 31px;
      line-height: 31px;

      &--blue {
        gap: 10px;
      }
    }
  }
}
</style>
