<template>
	<article class="moreAboutSubjects">
		<div class="moreAboutSubjects__card" v-for="subject in subjects" :key="subject.id">
			<span class="moreAboutSubjects__title">{{ subject.title }}</span>
			<p class="moreAboutSubjects__text" v-html="subject.text"></p>
		</div>
	</article>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		screenWidth: Number,
		additional: Array,
		title: String,
		subtitle: String,
	},

	computed: {
		...mapState({
			subjects: (state) => state.aboutSubjects.subjects,
		}),
	},
};
</script>

<style lang="scss" scoped>
.moreAboutSubjects {
	&__card {
		padding: 20px 25px 20px 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 9px solid white;
	}

	&__title {
		font-style: italic;
		font-weight: 600;
		font-size: 30px;
		line-height: 117%;
		text-transform: uppercase;
		color: #70c1ff;
	}

	&__text {
		font-weight: 400;
		font-size: 23px;
		line-height: 126%;
		text-align: right;
		color: #fff;
		max-width: 405px;
	}
}

@media (max-width: 430px) {
	.moreAboutSubjects {
		&__card {
			padding: 15px 22px;
			border-top: 12px solid white;
		}

		&__title {
			font-size: 25px;
			line-height: 140%;
		}

		&__text {
			font-size: 15px;
			line-height: 147%;
			max-width: 190px;
		}
	}
}
</style>
