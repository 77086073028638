<template>
  <section class="rulesPage">
    <div class="rulesPage__wrapper container">
      <div class="rulesPage__titleBlock">
        <h1 class="rulesPage__title">правила посещения</h1>
        <h2 class="rulesPage__title rulesPage__title--grey">
          <svg v-if="screenWidth > 1686" width="167" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m123.927 0-14.82 14.822 17.365 17.366H0v20.96h126.472l-17.365 17.367 14.82 14.822 42.663-42.669L123.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="134"
            height="69"
            viewBox="0 0 134 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M99.7431 0.601562L87.8873 12.4589L101.779 26.3523L0.601562 26.3523V43.1203L101.779 43.1203L87.8873 57.0137L99.7431 68.871L133.874 34.7363L99.7431 0.601562Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="68"
            height="35"
            viewBox="0 0 68 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.3716 0.800781L44.4437 6.72944L51.3895 13.6762L0.800781 13.6762V22.0601L51.3895 22.0601L44.4437 29.0068L50.3716 34.9355L67.4368 17.8681L50.3716 0.800781Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="121" height="24" fill="none">
            <path
              fill="#E6E6E6"
              d="m109.169.106-4.05 4.05 4.745 4.747H0v5.728h109.864l-4.745 4.746 4.05 4.05 11.659-11.66L109.169.107Z"
            />
          </svg>
          тренировки
        </h2>
      </div>
    </div>
  </section>
  <section class="rulesPage__whatIsNeed container">
    <div class="rulesPage__whatIsNeed-wrapper">
      <h2 class="rulesPage__whatIsNeed-title">Что необходимо для занятий в самокат-школе?</h2>
      <div class="rulesPage__whatIsNeed-content">
        <ol class="questions__question-answer-list">
          <li>
            Справка от терапевта об отсутствии ограничений
            <br v-if="screenWidth >= 640" />
            для занятия экстремальным спортом.
          </li>
          <li>
            <div>
              Расписка от родителей о том, что они и ученик ознакомлены
              <br v-if="screenWidth >= 640" />
              с правилами посещения скейт-парка:
              <div class="questions__question-answer-list-buttons">
                <a class="questions__question-answer-list-buttons--blue" :href="documents[0].file">Правила парка</a>
                <a class="questions__question-answer-list-buttons--blue" :href="documents[2].file">Расписка для детей</a>
                <a class="questions__question-answer-list-buttons--blue" :href="documents[1].file">Расписка для родителей</a>
              </div>
            </div>
          </li>
          <li>Копия свидетельства о рождении.</li>
          <li>
            Одежда должна быть удобна для катания: спортивные
            <br v-if="screenWidth >= 640" />
            штаны или шорты, кеды на плоской подошве.
          </li>
          <li>
            На каждую тренировку необходимо взять с собой свой трюковой самокат,
            <br v-if="screenWidth >= 640" />
            защиту (шлем, наколенники, налокотники), бутылку воды.
          </li>
        </ol>
      </div>
    </div>
    <img
      v-if="screenWidth > 640"
      class="rulesPage__whatIsNeed-img"
      src="@/assets/img/RulesImg.webp"
      alt="Rules image"
    />
  </section>
  <section class="questions container questions--blue">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #fff" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
  <QuestionPhoneComponent :screenWidth="screenWidth" />
</template>

<script>
import LineComponent from '@/components/LineComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import QuestionPhoneComponent from '@/components/QuestionPhoneComponent.vue';
import { mapState } from 'vuex';

export default {
  components: {
    QuestionComponent,
    QuestionPhoneComponent,
    LineComponent,
  },

  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      answers: (state) => state.homeQuestions.questions,
			documents: (state) => state.documents.documents,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
