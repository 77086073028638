<template>
	<swiper
		:navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
		:modules="modules"
		:slidesPerView="screenWidth > 640 ? 3.5 : 1"
		:centeredSlides="false"
		:spaceBetween="15"
		:grabCursor="true"
		class="mySwiper aboutPark__swiper"
	>
		<swiper-slide v-for="photo in photos" :key="photo.id">
			<img :src="photo.url" alt="Картинка" />
		</swiper-slide>
		<div class="aboutPark__navigation">
			<div class="swiper-button-prev aboutPark__navigation-prev"></div>
			<div class="swiper-button-next aboutPark__navigation-next"></div>
		</div>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		screenWidth: Number,
	},
	setup() {
		return {
			modules: [Navigation],
		};
	},

	computed: {
		...mapState({
			photos: (state) => state.aboutParkPhotos.photos,
		}),
	},
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';
</style>
