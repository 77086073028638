const topBanner = {
  state: {
    topSlides: [
      {
        img: require('@/assets/img/TopBanner/banner1.jpg'),
      },
      {
        img: require('@/assets/img/TopBanner/banner2.png'),
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default topBanner;
