import { v4 as uuidv4 } from 'uuid';

const media = {
  state: {
    links: [
      {
        id: uuidv4(),
        title: 2024,
        url: '',
        active: true,
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2023,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2022,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2021,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2020,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2019,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2018,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2017,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2016,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2015,
        url: '',
        class: 'activeYear',
      },
      {
        id: uuidv4(),
        title: 2014,
        url: '',
        class: 'activeYear',
      },
    ],
    year2024: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo1.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo2.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo3.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo4.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo5.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo6.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo7.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo8.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo9.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo10.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo11.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo12.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo13.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo14.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo15.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo16.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo17.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2024/photo18.png'),
      },
    ],
    year2023: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2022: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2022/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2022/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2022/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2022/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2022/photo5.webp'),
      },
    ],
    year2021: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2020: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2019: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2018: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2017: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2016: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2015: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
    year2014: [
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo1.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo2.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo3.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo4.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo5.webp'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/Media/2023/photo6.webp'),
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default media;
