<template>
	<AdditionalComponent
		:additional="additional"
		:screenWidth="screenWidth"
		title="стоимость"
		subtitle="проката инвентаря"
	/>
	<LineComponent v-if="screenWidth <= 430" />
	<AboutParkRulesComponent :screenWidth="screenWidth" />

	<LineComponent v-if="screenWidth <= 430" />
	<section class="feedback" v-if="screenWidth <= 430">
		<div class="feedback__wrapper container">
			<div class="feedback__titleBlock">
				<h3 class="feedback__title">Отзывы</h3>
				<h3 class="feedback__title feedback__title--grey">
					<svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
						<path
							fill="#E6E6E6"
							d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
						/>
					</svg>
					учеников и родителей
				</h3>
			</div>
		</div>
		<FeedbackSliderComponent :screenWidth="screenWidth" />
	</section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AdditionalComponent from '@/components/AdditionalComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue'

export default {
	components: {
		AdditionalComponent,
		LineComponent,
		AboutParkRulesComponent,
		FeedbackSliderComponent
	},
	props: {
		screenWidth: Number,
	},

	computed: {
		...mapState({
			group: (state) => state.advantagesCards.group,
			price: (state) => state.price.individual,
			additional: (state) => state.price.additional,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
