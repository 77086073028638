<template>
	<swiper
		:navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
		:slidesPerView="9"
		:centeredSlides="false"
		:spaceBetween="15"
		:grabCursor="true"
		class="mySwiper coachesListSlider__swiper"
	>
		<swiper-slide v-for="coach in coaches" :key="coach.id">
			<div class="coaches__card">
				<div class="coaches__card-img__wrapper">
					<router-link :to="coach.path"><img :src="coach.photo" :alt="coach.name" /></router-link>
					<router-link class="coaches__btn" :to="coach.path">
						<svg v-if="screenWidth > 640" xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none">
							<circle cx="21" cy="21" r="15" fill="#70C1FF" transform="rotate(-34.543 21.536 21.504)" />
							<path
								fill="#fff"
								d="M29.23 22.162a.93.93 0 0 0 0-1.314l-5.916-5.915A.93.93 0 1 0 22 16.247l5.258 5.258L22 26.763a.93.93 0 0 0 1.314 1.314l5.915-5.915Zm-14.73.272h14.072v-1.858H14.5v1.858Z"
							/>
						</svg>
						<svg v-if="screenWidth <= 430" width="37" height="38" fill="none">
							<circle cx="18.206" cy="19.079" r="13.091" fill="#70C1FF" transform="rotate(-34.543 18.206 19.079)" />
							<path
								fill="#fff"
								d="M24.957 19.656a.816.816 0 0 0 0-1.154l-5.192-5.192a.816.816 0 1 0-1.154 1.154l4.616 4.615-4.616 4.616a.816.816 0 0 0 1.154 1.153l5.192-5.192Zm-12.93.239h12.354v-1.632H12.027v1.632Z"
							/>
						</svg>
					</router-link>
				</div>
				<h4 class="coaches__name">{{ coach.name }}</h4>
				<div class="coaches__rating">
					<div class="coaches__rating-stars">
						<svg
							v-for="(star, index) in 5"
							:key="index"
							xmlns="http://www.w3.org/2000/svg"
							width="13"
							height="13"
							viewBox="0 0 13 13"
							fill="none"
						>
							<path
								d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
								fill="#252C37"
							/>
						</svg>
					</div>
					{{ coach.rating }}
				</div>
				<button class="coaches__signUp-btn ms_booking" :data-url="coach.idYclientsForm" v-if="addBtn">
					Записаться
				</button>
			</div>
		</swiper-slide>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
	props: {
		screenWidth: Number,
	},

	components: {
		Swiper,
		SwiperSlide,
	},

	computed: {
		...mapState({
			coaches: (state) => state.coaches.coaches,
		}),
	},
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';

.swiper {
	max-height: 240px;
}
</style>
