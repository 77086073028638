<template>
	<header class="header">
		<nav class="navigation container">
			<router-link class="navigation__logo" to="/"><img src="@/assets/img/logo.png" alt="logo" /></router-link>
			<div class="navigation__list">
				<router-link
					class="navigation__link"
					v-for="link in navigation"
					:key="link.id"
					:to="link.url"
					@mouseover="mouseOver(link.id)"
					>{{ link.title }}</router-link
				>
			</div>
			<router-link class="navigation__location" to="/contacts" @mouseover="mouseOver('location')">МОСКВА, KSS PARK</router-link>
			<button class="navigation__sing-up ms_booking">ЗАПИСАТЬСЯ НА ТРЕНИРОВКУ</button>

			<NavigationMobileComp />

			<div
				v-for="(submenu, index) in submenus"
				:key="index"
				:class="'navigation__submenu navigation__submenu' + (index + 1)"
				@mouseleave="closeSubmenu(index + 1)"
			>
				<router-link class="navigation__submenu-link" :to="subLink.url" v-for="subLink in submenu" :key="subLink.id">{{
					subLink.title
				}}</router-link>
			</div>
		</nav>
	</header>
</template>

<script>
import { mapState } from 'vuex';
import LineComponent from './LineComponent.vue';
import NavigationMobileComp from './NavigationMobileComp.vue';
export default {
	name: 'Header Navigation',
	data() {
		return {};
	},
	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
	computed: {
		...mapState({
			navigation: (state) => state.headerNavigation.navigation,
			submenus: (state) => state.headerNavigation.submenu,
		}),
	},
	created() {
		document.addEventListener('click', (event) => {
			const navigationList = document.querySelector('.navigation__list');
			if (!navigationList.contains(event.target)) {
				const submenu1 = document.querySelector('.navigation__submenu1');
				const submenu2 = document.querySelector('.navigation__submenu2');
				const submenu3 = document.querySelector('.navigation__submenu3');
				const submenu4 = document.querySelector('.navigation__submenu4');
				submenu1.style.display = 'none';
				submenu2.style.display = 'none';
				submenu3.style.display = 'none';
				submenu4.style.display = 'none';
			}
		});
	},
	methods: {
		mouseOver(id) {
			const submenu1 = document.querySelector('.navigation__submenu1');
			const submenu2 = document.querySelector('.navigation__submenu2');
			const submenu3 = document.querySelector('.navigation__submenu3');
			const submenu4 = document.querySelector('.navigation__submenu4');
			const location = document.querySelector('.navigation__location');
			if (id === 2) {
				if (submenu1) submenu1.style.display = 'flex';
				if (submenu2) submenu2.style.display = 'none';
				if (submenu3) submenu3.style.display = 'none';
				if (submenu4) submenu4.style.display = 'none';
			} else if (id === 1) {
				if (submenu1) submenu1.style.display = 'none';
				if (submenu2) submenu2.style.display = 'flex';
				if (submenu3) submenu3.style.display = 'none';
				if (submenu4) submenu4.style.display = 'none';
			} else if (id === 3) {
				if (submenu1) submenu1.style.display = 'none';
				if (submenu2) submenu2.style.display = 'none';
				if (submenu3) submenu3.style.display = 'none';
				if (submenu4) submenu4.style.display = 'none';
			}else if (id === 5) {
				if (submenu1) submenu1.style.display = 'none';
				if (submenu2) submenu2.style.display = 'none';
				if (submenu3) submenu3.style.display = 'flex';
				if (submenu4) submenu4.style.display = 'none';
			} else if (id === 4) {
				if (submenu1) submenu1.style.display = 'none';
				if (submenu2) submenu2.style.display = 'none';
				if (submenu3) submenu3.style.display = 'none';
				if (submenu4) submenu4.style.display = 'flex';
			} else if (id === 'location') {
				if (submenu1) submenu1.style.display = 'none';
				if (submenu2) submenu2.style.display = 'none';
				if (submenu3) submenu3.style.display = 'none';
				if (submenu4) submenu4.style.display = 'none';
			}
		},

		closeSubmenu(id) {
			const submenu1 = document.querySelector('.navigation__submenu1');
			const submenu2 = document.querySelector('.navigation__submenu2');
			const submenu3 = document.querySelector('.navigation__submenu3');
			const submenu4 = document.querySelector('.navigation__submenu4');
			if (id === 1) {
				submenu1.style.display = 'none';
				submenu2.style.display = 'none';
				submenu3.style.display = 'none';
				submenu4.style.display = 'none';
			} else if (id === 2) {
				submenu1.style.display = 'none';
				submenu2.style.display = 'none';
				submenu3.style.display = 'none';
				submenu4.style.display = 'none';
			} else if (id === 3) {
				submenu1.style.display = 'none';
				submenu2.style.display = 'none';
				submenu3.style.display = 'none';
				submenu4.style.display = 'none';
			} else if (id === 4) {
				submenu1.style.display = 'none';
				submenu2.style.display = 'none';
				submenu3.style.display = 'none';
				submenu4.style.display = 'none';
			} else {
				const submenus = document.querySelectorAll('.navigation__submenu');
				submenus.forEach((submenu) => {
					submenu.style.display = 'none';
				});
			}
		},
	},
	components: { LineComponent, NavigationMobileComp },
};
</script>

<style lang="scss" scoped></style>
