<template>
  <section class="trainingPage">
    <div class="trainingPage__wrapper container">
      <div class="trainingPage__titleBlock">
        <h1 class="trainingPage__title">обучающие</h1>
        <h2 class="trainingPage__title trainingPage__title--grey">
          <svg v-if="screenWidth > 1686" width="356" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m312.927 0-14.82 14.822 17.365 17.366H0v20.96h315.472l-17.365 17.367 14.82 14.822 42.663-42.669L312.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth <= 1686 && screenWidth >= 1356"
            width="286"
            height="69"
            viewBox="0 0 286 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M251.142 0.601562L239.287 12.4589L253.178 26.3523L0.800781 26.3523V43.1203L253.178 43.1203L239.287 57.0137L251.142 68.871L285.273 34.7363L251.142 0.601562Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="143"
            height="35"
            viewBox="0 0 143 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M125.571 0.796875L119.643 6.72554L126.589 13.6723L0.400391 13.6723V22.0562L126.589 22.0562L119.643 29.0029L125.571 34.9316L142.636 17.8642L125.571 0.796875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="127" height="31" fill="none">
            <path
              fill="#E6E6E6"
              d="m111.963.413-5.172 5.174 6.061 6.062H0v7.316h112.852l-6.061 6.061 5.172 5.174 14.892-14.893L111.963.413Z"
            />
          </svg>
          видео
        </h2>
      </div>
      <div class="trainingPage__video" v-for="(video, index) in videos" :key="video.id" :class="video.class">
        <video :src="video.src" :poster="video.img" controls></video>
        <h3>{{ video.title }}</h3>
        <p v-if="screenWidth > 640">{{ video.description }}</p>
        <p v-if="screenWidth <= 430 && index === 0">{{ video.description }}</p>
      </div>
      <span class="trainingPage__video-newTag">НОВИНКА!</span>
    </div>
  </section>
  <LineComponent />
  <section class="questions container" :class="screenWidth > 640 ? 'questions--blue' : null">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #fff" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
  <QuestionPhoneComponent :screenWidth="screenWidth" />
</template>

<script>
import LineComponent from '@/components/LineComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import QuestionPhoneComponent from '@/components/QuestionPhoneComponent.vue';
import { mapState } from 'vuex';

export default {
  components: {
    QuestionComponent,
    QuestionPhoneComponent,
    LineComponent,
  },

  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      videos: (state) => state.trainingVideo.videos,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
