<template>
  <div class="navigation__mobileMenu">
    <button class="navigation__mobileMenu-btn" @click="toggleMenu">
      <svg width="25" height="24" fill="none">
        <path stroke="#0091FF" stroke-width="3" d="M0 2h24.348M0 12h24.348M0 22h24.348" />
      </svg>
    </button>
    <div class="navigation__mobileMenu-hoverMenu" v-show="isMenuOpen">
      <div class="navigation__mobileMenu-top">
        <details class="navigation__mobileMenu-filials">
          <summary>
            <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.62521 20.6997L8.62517 20.6997C6.82939 19.8604 5.30636 18.2385 4.22794 16.2521C3.15062 14.2679 2.55 11.9811 2.55 9.893C2.55 5.8238 5.78642 2.55 9.75 2.55C13.7136 2.55 16.95 5.8238 16.95 9.893C16.95 11.9811 16.3494 14.2689 15.2721 16.2521L15.2721 16.2521C14.1936 18.2385 12.6706 19.8604 10.8738 20.6997L10.8738 20.6997C10.5219 20.8646 10.1381 20.95 9.7495 20.95C9.36092 20.95 8.97709 20.8646 8.62521 20.6997ZM9.75 0.95C4.87653 0.95 0.95 4.96725 0.95 9.893C0.95 12.2669 1.62638 14.8151 2.82207 17.0159C4.01565 19.2145 5.76061 21.1266 7.94782 22.1493C8.5119 22.4134 9.12715 22.5503 9.75 22.5503C10.3729 22.5503 10.9881 22.4134 11.5522 22.1493C13.7394 21.1266 15.4844 19.2135 16.6779 17.0159C17.8736 14.8151 18.55 12.2669 18.55 9.893C18.55 4.96725 14.6235 0.95 9.75 0.95ZM8.9081 7.71746C9.17501 7.6069 9.46109 7.55 9.75 7.55C10.3335 7.55 10.8931 7.78179 11.3056 8.19437C11.7182 8.60695 11.95 9.16652 11.95 9.75C11.95 10.3335 11.7182 10.8931 11.3056 11.3056C10.8931 11.7182 10.3335 11.95 9.75 11.95C9.46109 11.95 9.17501 11.8931 8.9081 11.7825C8.64118 11.672 8.39865 11.5099 8.19437 11.3056C7.99008 11.1013 7.82803 10.8588 7.71747 10.5919C7.6069 10.325 7.55 10.0389 7.55 9.75C7.55 9.46109 7.6069 9.17501 7.71747 8.9081C7.82803 8.64118 7.99008 8.39865 8.19437 8.19437C8.39865 7.99008 8.64118 7.82802 8.9081 7.71746ZM7.06299 7.06299C6.35036 7.77563 5.95 8.74218 5.95 9.75C5.95 10.7578 6.35036 11.7244 7.06299 12.437C7.77563 13.1496 8.74218 13.55 9.75 13.55C10.7578 13.55 11.7244 13.1496 12.437 12.437C13.1496 11.7244 13.55 10.7578 13.55 9.75C13.55 8.74218 13.1496 7.77563 12.437 7.06299C11.7244 6.35036 10.7578 5.95 9.75 5.95C8.74218 5.95 7.77563 6.35036 7.06299 7.06299Z"
                fill="white"
                stroke="white"
                stroke-width="0.1"
              />
            </svg>
            МОСКВА, KSS PARK
          </summary>
        </details>
        <button class="navigation__mobileMenu-closeBtn" @click="toggleMenu">
          <svg width="28" height="28" fill="none"><path stroke="#fff" stroke-width="3" d="M26 2 2 26M2 2l24 24" /></svg>
        </button>
      </div>
      <line-component class="navigation__mobileMenu-line" />

      <div class="hoverMenu__block">
        <router-link class="hoverMenu__leftLink" to="/about" @click="closeMenu">О НАС</router-link>
        <div class="hoverMenu__rightBlock">
          <router-link class="hoverMenu__rightLink" to="/scooter-sport" @click="closeMenu">
            Самокатный спорт
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/about" @click="closeMenu">О самокат-школе</router-link>
          <router-link class="hoverMenu__rightLink" to="/kss-park" @click="closeMenu">О скейт-парке</router-link>
          <router-link class="hoverMenu__rightLink" to="/rules" @click="closeMenu">Правила посещения</router-link>
          <router-link class="hoverMenu__rightLink" to="/faq" @click="closeMenu">Часто задаваемые вопросы</router-link>
        </div>
      </div>
      <line-component class="navigation__mobileMenu-line" />
      <div class="hoverMenu__block">
        <router-link class="hoverMenu__leftLink" to="/education" @click="closeMenu">ОБУЧЕНИЕ</router-link>
        <div class="hoverMenu__rightBlock">
          <router-link class="hoverMenu__rightLink" to="/group" @click="closeMenu">Групповые тренировки</router-link>
          <router-link class="hoverMenu__rightLink" to="/individual" @click="closeMenu">
            Индивидуальные тренировки
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/training" @click="closeMenu">Обучалки</router-link>
        </div>
      </div>
      <line-component class="navigation__mobileMenu-line" />
      <div class="hoverMenu__block">
        <router-link class="hoverMenu__leftLink" to="/services-and-price" @click="closeMenu">УСЛУГИ И ЦЕНЫ</router-link>
        <div class="hoverMenu__rightBlock">
          <router-link class="hoverMenu__rightLink" to="/services-and-price/group" @click="closeMenu">
            Групповые тренировки
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/services-and-price/individual" @click="closeMenu">
            Индивидуальные тренировки
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/services-and-price/free-driving" @click="closeMenu">
            Свободное катание
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/services-and-price/park" @click="closeMenu">
            Аренда парка
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/services-and-price/rental" @click="closeMenu">
            Прокат
          </router-link>
          <router-link class="hoverMenu__rightLink" to="/services-and-price/events" @click="closeMenu">
            Организация мероприятий
          </router-link>
        </div>
      </div>
      <line-component class="navigation__mobileMenu-line" />
      <router-link
        class="hoverMenu__leftLink hoverMenu__leftLink--width hoverMenu__leftLink hoverMenu__leftLink--center"
        to="/team"
        @click="closeMenu"
      >
        ТРЕНЕРСКИЙ СОСТАВ
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M35.2822 13.1254C40.2718 20.3736 38.4408 30.2942 31.1927 35.2837C23.9446 40.2733 14.024 38.4424 9.0344 31.1942C4.04484 23.9461 5.87578 14.0255 13.1239 9.03591C20.3721 4.04635 30.2927 5.87729 35.2822 13.1254Z"
            fill="#70C1FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0581 15.1346L30.3777 21.4542C30.7655 21.842 30.7655 22.4707 30.3777 22.8585L24.0581 29.1781C23.6703 29.5659 23.0416 29.5659 22.6538 29.1781C22.266 28.7903 22.266 28.1615 22.6538 27.7737L27.2781 23.1494L14.6406 23.1494L14.6406 21.1633L27.2781 21.1633L22.6538 16.5389C22.266 16.1511 22.266 15.5224 22.6538 15.1346C23.0416 14.7468 23.6703 14.7468 24.0581 15.1346Z"
            fill="white"
          />
        </svg>
      </router-link>
      <line-component class="navigation__mobileMenu-line" />
      <div class="hoverMenu__block">
        <router-link class="hoverMenu__leftLink" to="/news" @click="closeMenu">СОБЫТИЯ</router-link>
        <div class="hoverMenu__rightBlock">
          <router-link class="hoverMenu__rightLink" to="/events" @click="closeMenu">Мероприятия</router-link>
          <router-link class="hoverMenu__rightLink" to="/media" @click="closeMenu">Медиа</router-link>
        </div>
      </div>
      <line-component class="navigation__mobileMenu-line" />
    </div>
  </div>
</template>

<script>
import LineComponent from './LineComponent.vue';
export default {
  name: 'Header Mobile Navigation',
  components: { LineComponent },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;

      const body = document.querySelector('body');
      if (this.isMenuOpen) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    },

    closeMenu() {
      this.isMenuOpen = false;

      document.querySelector('body').style.overflow = '';
    },
  },
};
</script>

<style lang="scss" scoped></style>
