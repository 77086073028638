import { v4 as uuidv4 } from 'uuid';

const coaches = {
  state: {
    coaches: [
      {
        id: uuidv4(),
        name: 'Юрий Малина',
        rating: 12,
        photo: require('@/assets/img/Coaches/malina.webp'),
        path: '/team/akimov',
        idYclients: 'w388672',
        idYclientsForm: 'https://w388672.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Илья Тарабрин',
        rating: 52,
        photo: require('@/assets/img/Coaches/tarabrin.webp'),
        path: '/team/tarabrin',
        idYclients: 'w388672',
        idYclientsForm: 'https://w388672.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Костя Козлов',
        rating: 8,
        photo: require('@/assets/img/Coaches/kozlov.webp'),
        path: '/team/kozlov',
        idYclients: 'w1062805',
        idYclientsForm: 'https://w1062805.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Захар Михин',
        rating: 29,
        photo: require('@/assets/img/Coaches/mihin.webp'),
        path: '/team/mihin',
        idYclients: 'w388672',
        idYclientsForm: 'https://w388672.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Дмитрий Фазолов',
        rating: 16,
        photo: require('@/assets/img/Coaches/fazolov.webp'),
        path: '/team/fazolov',
        idYclients: 'w388672',
        idYclientsForm: 'https://w388672.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Стас Басанский',
        rating: 19,
        photo: require('@/assets/img/Coaches/basanskiy.webp'),
        path: '/team/basanskii',
        idYclients: 'w1073215',
        idYclientsForm: 'https://w1073215.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Иван Соловьев',
        rating: 12,
        photo: require('@/assets/img/Coaches/soloviev.webp'),
        path: '/team/soloviev',
        idYclients: 'w1073212',
        idYclientsForm: 'https://w1073212.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Артём Кравченко',
        rating: 9,
        photo: require('@/assets/img/Coaches/kravchenko.webp'),
        path: '/team/kravchenko',
        idYclients: 'w1073189',
        idYclientsForm: 'https://w1073189.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Даня Попелнуха',
        rating: 9,
        photo: require('@/assets/img/Coaches/popelnuha.webp'),
        path: '/team/popelnuha',
        idYclients: 'w1053397',
        idYclientsForm: 'https://w1053397.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Альберт Волков',
        rating: 9,
        photo: require('@/assets/img/Coaches/volkov.webp'),
        path: '/team/volkov',
        idYclients: 'w1059522',
        idYclientsForm: 'https://w1059522.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Саша Хохлов',
        rating: 9,
        photo: require('@/assets/img/Coaches/hohlov.webp'),
        path: '/team/hohlov',
        idYclients: 'w1059528',
        idYclientsForm: 'https://w1059528.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Никита Чильчагов',
        rating: 0,
        photo: require('@/assets/img/Coaches/chilchagov.webp'),
        path: '/team/chilchagov',
        idYclients: 'n1106421',
        idYclientsForm: 'https://n1106421.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Саша Мананников',
        rating: 0,
        photo: require('@/assets/img/Coaches/manannikov.webp'),
        path: '/team/manannikov',
        idYclients: 'n1106424',
        idYclientsForm: 'https://n1106424.yclients.com/widgetJS',
      },
      {
        id: uuidv4(),
        name: 'Артём Кочин',
        rating: 0,
        photo: require('@/assets/img/Coaches/kochin.webp'),
        path: '/team/kochin',
        idYclients: 'w388672',
        idYclientsForm: 'https://w388672.yclients.com/widgetJS',
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default coaches;
