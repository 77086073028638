<template>
  <swiper
    :modules="modules"
    :slidesPerView="9"
    :centeredSlides="false"
    :spaceBetween="15"
    :grabCursor="true"
    class="mySwiper coachesSliderMobile__swiper"
  >
    <swiper-slide v-for="coach in coaches" :key="coach.id">
      <div class="coaches__card">
        <div class="coaches__card-img__wrapper">
          <router-link :to="coach.path"><img :src="coach.photo" :alt="coach.name" /></router-link>
          <router-link class="coaches__btn" :to="coach.path">
            <svg
              v-if="screenWidth <= 1356 && screenWidth >= 640"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="9.21558"
                cy="8.30058"
                r="5.96499"
                transform="rotate(-34.5432 9.21558 8.30058)"
                fill="#70C1FF"
              />
              <path
                d="M12.2921 8.55976C12.4372 8.41457 12.4372 8.17918 12.2921 8.03399L9.92612 5.66807C9.78094 5.52288 9.54555 5.52288 9.40036 5.66807C9.25518 5.81325 9.25518 6.04864 9.40036 6.19383L11.5034 8.29688L9.40036 10.3999C9.25518 10.5451 9.25518 10.7805 9.40036 10.9257C9.54555 11.0709 9.78094 11.0709 9.92612 10.9257L12.2921 8.55976ZM6.40039 8.66864L12.0292 8.66865L12.0292 7.92511L6.40039 7.92511L6.40039 8.66864Z"
                fill="white"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              width="35"
              height="34"
              viewBox="0 0 35 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.4351" cy="16.5855" r="11.93" transform="rotate(-34.5432 17.4351 16.5855)" fill="#70C1FF" />
              <path
                d="M23.588 17.1039C23.8784 16.8135 23.8784 16.3427 23.588 16.0524L18.8562 11.3205C18.5658 11.0301 18.095 11.0301 17.8046 11.3205C17.5143 11.6109 17.5143 12.0817 17.8046 12.372L22.0107 16.5781L17.8046 20.7842C17.5143 21.0746 17.5143 21.5454 17.8046 21.8357C18.095 22.1261 18.5658 22.1261 18.8562 21.8357L23.588 17.1039ZM11.8047 17.3217L23.0622 17.3217L23.0622 15.8346L11.8047 15.8346L11.8047 17.3217Z"
                fill="white"
              />
            </svg>
          </router-link>
        </div>
        <h4 class="coaches__name">{{ coach.name }}</h4>
        <div class="coaches__rating">
          <div class="coaches__rating-stars">
            <svg
              v-if="screenWidth <= 1356 && screenWidth >= 640"
              v-for="(star, index) in 5"
              :key="index"
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="5"
              viewBox="0 0 13 13"
              fill="none"
            >
              <path
                d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
                fill="#252C37"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              v-for="(star, index) in 5"
              :key="index"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.27031 0.757812L6.43779 4.35092H10.2158L7.15932 6.57159L8.3268 10.1647L5.27031 7.94404L2.21383 10.1647L3.3813 6.57159L0.324819 4.35092H4.10284L5.27031 0.757812Z"
                fill="#252C37"
              />
            </svg>
          </div>
          {{ coach.rating }}
        </div>
        <button class="coaches__signUp-btn" v-if="addBtn">Записаться</button>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';
import CoachesListComponent from './CoachesListComponent.vue';

export default {
  props: {
    screenWidth: Number,
    addBtn: Boolean,
  },

  components: {
    Swiper,
    SwiperSlide,
    CoachesListComponent,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      coaches: (state) => state.coaches.coaches,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';

.swiper {
  height: 100px;
}

@media (max-width: 1686px) and (min-width: 1356px) {
  .swiper {
    height: 170px;
  }
}
</style>
