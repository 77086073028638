<template>
  <nav class="breadcrumbs container" v-if="screenWidth > 640">
    <router-link class="breadcrumbs__link" v-for="(link, index) in getLinks" :key="link.id" :to="link.url">
      {{ link.title }}
    </router-link>
  </nav>
  <nav class="breadcrumbs container" v-if="screenWidth <= 430">
    <!-- <router-link class="breadcrumbs__link" v-for="(link, index) in getLinks" :key="link.id" :to="link.url" @click="open(index)"
			>{{ link.title }}
			<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="link.isClose">
				<path
					d="M35.2822 13.1235C40.2718 20.3716 38.4408 30.2922 31.1927 35.2818C23.9446 40.2713 14.024 38.4404 9.0344 31.1923C4.04484 23.9441 5.87578 14.0235 13.1239 9.03396C20.3721 4.0444 30.2927 5.87534 35.2822 13.1235Z"
					fill="#0091FF"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M24.0581 15.1326L30.3777 21.4522C30.7655 21.84 30.7655 22.4688 30.3777 22.8566L24.0581 29.1761C23.6703 29.5639 23.0416 29.5639 22.6538 29.1761C22.266 28.7883 22.266 28.1596 22.6538 27.7718L27.2781 23.1474L14.6406 23.1474L14.6406 21.1614L27.2781 21.1614L22.6538 16.537C22.266 16.1492 22.266 15.5204 22.6538 15.1326C23.0416 14.7448 23.6703 14.7448 24.0581 15.1326Z"
					fill="white"
				/>
			</svg>
			<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" v-show="link.isOpen">
				<path
					d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
					fill="#0091FF"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
					fill="white"
				/>
			</svg>
		</router-link> -->
    <router-link
      class="breadcrumbs__link"
      v-for="(link, index) in getLinks"
      :key="link.id"
      :to="link.url"
      v-if="this.$route.path === '/services-and-price'"
    >
      {{ link.title }}
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.2822 13.1235C40.2718 20.3716 38.4408 30.2922 31.1927 35.2818C23.9446 40.2713 14.024 38.4404 9.0344 31.1923C4.04484 23.9441 5.87578 14.0235 13.1239 9.03396C20.3721 4.0444 30.2927 5.87534 35.2822 13.1235Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.0581 15.1326L30.3777 21.4522C30.7655 21.84 30.7655 22.4688 30.3777 22.8566L24.0581 29.1761C23.6703 29.5639 23.0416 29.5639 22.6538 29.1761C22.266 28.7883 22.266 28.1596 22.6538 27.7718L27.2781 23.1474L14.6406 23.1474L14.6406 21.1614L27.2781 21.1614L22.6538 16.537C22.266 16.1492 22.266 15.5204 22.6538 15.1326C23.0416 14.7448 23.6703 14.7448 24.0581 15.1326Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/group'"
    >
      ГРУППОВЫЕ ТРЕНИРОВКИ
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/individual'"
    >
      ИНДИВИДУАЛЬНЫЕ ТРЕНИРОВКИ
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/park'"
    >
      АРЕНДА СКЕЙТ-ПАРКА
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/rental'"
    >
      ПРОКАТ
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/free-driving'"
    >
      СВОБОДНОЕ КАТАНИЕ
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
    <router-link
      class="breadcrumbs__link breadcrumbs__link--isOpen"
      to="/services-and-price"
      v-if="this.$route.path === '/services-and-price/events'"
    >
      ОРГАНИЗАЦИЯ МЕРОПРИЯТИЙ
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.191 35.2822C23.9428 40.2718 14.0222 38.4408 9.03266 31.1927C4.0431 23.9446 5.87404 14.024 13.1222 9.0344C20.3703 4.04484 30.2909 5.87578 35.2805 13.1239C40.2701 20.3721 38.4391 30.2927 31.191 35.2822Z"
          fill="#0091FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1818 24.0581L22.8622 30.3777C22.4744 30.7655 21.8457 30.7655 21.4579 30.3777L15.1383 24.0581C14.7505 23.6703 14.7505 23.0416 15.1383 22.6538C15.5261 22.266 16.1549 22.266 16.5427 22.6538L21.167 27.2781L21.167 14.6406L23.1531 14.6406L23.1531 27.2781L27.7775 22.6538C28.1653 22.266 28.794 22.266 29.1818 22.6538C29.5696 23.0416 29.5696 23.6703 29.1818 24.0581Z"
          fill="white"
        />
      </svg>
    </router-link>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapGetters('breadcrumbs', ['getLinks']),
  },
};
</script>

<style lang="scss" scoped></style>
