<template>
  <section class="aboutPark">
    <div class="aboutPark__wrapper container">
      <div class="aboutPark__titleBlock">
        <div class="aboutPark__titleBlock-topBlock">
          <h1 class="aboutPark__title">о парке</h1>
          <img v-if="screenWidth <= 430" class="thumb aboutPark__thumb0" src="@/assets/img/thumbs2.svg" alt="thumb" />
        </div>
        <h2 class="aboutPark__title aboutPark__title--grey">
          <svg v-if="screenWidth > 1686" width="116" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m72.927 0-14.82 14.822 17.365 17.366H0v20.96h75.472L58.107 70.515l14.82 14.822 42.663-42.669L72.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="93"
            height="69"
            viewBox="0 0 93 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M58.5407 0.601562L46.6849 12.4589L60.5766 26.3523L0.199219 26.3523V43.1203L60.5766 43.1203L46.6849 57.0137L58.5407 68.871L92.6713 34.7363L58.5407 0.601562Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="47"
            height="35"
            viewBox="0 0 47 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7704 0.796875L23.8425 6.72554L30.7883 13.6723L0.599609 13.6723V22.0562L30.7883 22.0562L23.8425 29.0029L29.7704 34.9316L46.8356 17.8642L29.7704 0.796875Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="38" height="31" fill="none">
            <path
              fill="#E6E6E6"
              d="m22.963.413-5.172 5.174 6.06 6.062H0v7.316h23.852l-6.061 6.061 5.172 5.174 14.892-14.893L22.964.413Z"
            />
          </svg>
          KSS PARK
        </h2>
      </div>
      <div class="aboutPark__address" v-if="screenWidth > 640">
        <router-link to="/contacts" class="aboutPark__address-btn">
          ЯНДЕКС КАРТЫ
          <svg v-if="screenWidth > 1686" width="34" height="16" fill="none">
            <path
              fill="#fff"
              d="M33.362 8.707a1 1 0 0 0 0-1.414L26.998.929a1 1 0 1 0-1.415 1.414L31.24 8l-5.656 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM.959 9h31.696V7H.959v2Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="27"
            height="13"
            viewBox="0 0 27 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2929 6.97975C26.6053 6.66733 26.6053 6.1608 26.2929 5.84838L21.2017 0.757208C20.8893 0.444789 20.3828 0.444789 20.0703 0.757208C19.7579 1.06963 19.7579 1.57616 20.0703 1.88858L24.5958 6.41406L20.0703 10.9395C19.7579 11.252 19.7579 11.7585 20.0703 12.0709C20.3828 12.3833 20.8893 12.3833 21.2017 12.0709L26.2929 6.97975ZM0.371094 7.21406H25.7272V5.61406H0.371094V7.21406Z"
              fill="white"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="14"
            height="7"
            viewBox="0 0 14 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1464 3.48206C13.3026 3.32585 13.3026 3.07259 13.1464 2.91638L10.6009 0.370792C10.4446 0.214582 10.1914 0.214582 10.0352 0.370792C9.87896 0.527001 9.87896 0.780267 10.0352 0.936477L12.2979 3.19922L10.0352 5.46196C9.87896 5.61817 9.87896 5.87144 10.0352 6.02765C10.1914 6.18386 10.4446 6.18386 10.6009 6.02765L13.1464 3.48206ZM0.185547 3.59922H12.8636V2.79922H0.185547V3.59922Z"
              fill="white"
            />
          </svg>
        </router-link>
        <h2 class="aboutPark__address-title">Москва, Электролитный проезд 3с19</h2>
      </div>
      <h2 v-if="screenWidth <= 430" class="aboutPark__address-title">Москва, Электролитный проезд 3с19</h2>
    </div>
  </section>
  <img class="aboutPark__mainPhoto" src="@/assets/img/ParkBanner.webp" alt="Park banner" />

  <LineComponent v-if="screenWidth >= 640" />

  <section class="aboutPark__descriptionCards container">
    <article class="aboutPark__descriptionCards-blue aboutPark__descriptionCards-card">
      <h3 class="aboutPark__descriptionCards-title">KSS park</h3>
      <p class="aboutPark__descriptionCards-text">
        Основная площадка для тренировок — KSS park. Для ребёнка в нем есть все необходимое для последовательного и
        безопасного изучения трюков, а для родителей всегда есть комфортные диваны с панорамным обзором, чтобы не
        упускать из виду своих маленьких спортсменов и спортсменок.
      </p>
      <img class="thumb aboutPark__thumb1" v-if="screenWidth >= 640" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb aboutPark__thumb1" v-if="screenWidth < 640" src="@/assets/img/thumbs1.svg" alt="thumb" />
      <img class="thumb aboutPark__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </article>
    <article class="aboutPark__descriptionCards-lightblue aboutPark__descriptionCards-card">
      <h3 class="aboutPark__descriptionCards-title">
        KSS park — всесезонное место для тренировок . Комфортная температура на тренировках в любое время года,
        идеальное освещение для съемки и всегда приветливый коллектив.
      </h3>
      <p class="aboutPark__descriptionCards-text">Москва, Электролитный проезд 3с19</p>
    </article>
    <article class="aboutPark__descriptionCards-black aboutPark__descriptionCards-card">
      <h3 class="aboutPark__descriptionCards-title">
        kss park спроектирован таким образом, что подходит как начинающим райдерам, так и профессионалам.
      </h3>
      <a href="#schemeOfPark" class="aboutPark__descriptionCards-btn">
        схема парка KSS
        <svg v-if="screenWidth > 1686" width="16" height="17" fill="none">
          <path
            fill="#fff"
            d="M7.293 16.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L8 14.586 2.343 8.929A1 1 0 0 0 .93 10.343l6.364 6.364ZM7 0v16h2V0H7Z"
          />
        </svg>
        <svg
          v-if="screenWidth >= 1356 && screenWidth <= 1686"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.63353 14.1704C5.94595 14.4828 6.45249 14.4828 6.7649 14.1704L11.8561 9.0792C12.1685 8.76678 12.1685 8.26025 11.8561 7.94783C11.5437 7.63541 11.0371 7.63541 10.7247 7.94783L6.19922 12.4733L1.67374 7.94783C1.36132 7.63541 0.854784 7.63541 0.542365 7.94783C0.229946 8.26025 0.229946 8.76679 0.542365 9.0792L5.63353 14.1704ZM5.39922 0.804688L5.39922 13.6047L6.99922 13.6047L6.99922 0.804687L5.39922 0.804688Z"
            fill="white"
          />
        </svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth < 1356"
          width="7"
          height="8"
          viewBox="0 0 7 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.31677 7.08128C3.47298 7.23749 3.72624 7.23749 3.88245 7.08128L6.42804 4.5357C6.58425 4.37949 6.58425 4.12622 6.42804 3.97001C6.27183 3.8138 6.01856 3.8138 5.86235 3.97001L3.59961 6.23275L1.33687 3.97001C1.18066 3.8138 0.927392 3.8138 0.771182 3.97001C0.614973 4.12622 0.614973 4.37949 0.771182 4.5357L3.31677 7.08128ZM3.19961 0.398438L3.19961 6.79844L3.99961 6.79844L3.99961 0.398437L3.19961 0.398438Z"
            fill="white"
          />
        </svg>
        <svg
          v-if="screenWidth <= 430"
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
            fill="white"
          />
        </svg>
      </a>
      <img class="thumb aboutPark__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
      <img class="thumb aboutPark__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
    </article>
  </section>

  <LineComponent />

  <section class="aboutPark__scheme">
    <div class="aboutPark__scheme-wrapper container">
      <div class="aboutPark__scheme-titleBlock">
        <h3 class="aboutPark__scheme-title" id="schemeOfPark">схема парка</h3>
        <h3 class="aboutPark__scheme-title aboutPark__scheme-title--grey">
          <svg v-if="screenWidth > 1686" width="141" height="39" fill="none">
            <path
              fill="#E6E6E6"
              d="m121.797.814-6.632 6.634 7.771 7.772H0v9.38h122.936l-7.771 7.772 6.632 6.633L140.89 19.91 121.797.814Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="113"
            height="31"
            viewBox="0 0 113 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M97.6369 0.257812L92.331 5.56431L98.548 11.782L0.199219 11.782V19.2862L98.548 19.2862L92.331 25.5039L97.6369 30.8104L112.911 15.5341L97.6369 0.257812Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth < 1356"
            width="57"
            height="16"
            viewBox="0 0 57 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.3184 0.125L46.6655 2.77825L49.774 5.88711L0.599609 5.8871V9.63917L49.774 9.63917L46.6655 12.748L49.3184 15.4013L56.9557 7.76314L49.3184 0.125Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="82" height="21" fill="none">
            <path
              fill="#E6E6E6"
              d="M70.99-.003 67.35 3.635l4.263 4.263H0v5.145h71.614l-4.263 4.263 3.638 3.639L81.462 10.47 70.989-.003Z"
            />
          </svg>
          kss park
        </h3>
      </div>
    </div>
    <div class="aboutPark__scheme-numbered">
      <div class="aboutPark__scheme-schemePark">
        <img src="@/assets/img/SchemePark.webp" alt="Схема парка" />
        <div
          v-for="item in schemeForTippy"
          :key="item.id"
          v-tippy="item.text"
          class="aboutPark__scheme-schemePark-number"
          :class="'aboutPark__scheme-schemePark-number-num' + item.id"
        >
          {{ item.id }}
        </div>
      </div>
    </div>
    <SchemeParkComponent :screenWidth="screenWidth" />
  </section>

  <LineComponent />

  <AboutParkRulesComponent :screenWidth="screenWidth" />

  <LineComponent />

  <section class="aboutPark__slider">
    <div class="aboutPark__slider__wrapper container" v-if="screenWidth <= 430">
      <div class="aboutPark__slider__titleBlock">
        <h3 class="aboutPark__slider__title">Фото парка</h3>
      </div>
    </div>
    <AboutParkSliderComponent :screenWidth="screenWidth" />
  </section>

  <LineComponent />

  <section class="questions container">
    <div class="questions__wrapper">
      <h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
      <LineComponent style="background-color: #57606f" />
      <QuestionComponent :screenWidth="screenWidth" />
    </div>
  </section>
</template>

<script>
import AboutParkSliderComponent from '@/components/AboutParkSliderComponent.vue';
import AboutParkRulesComponent from '@/components/AboutParkRulesComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import SchemeParkComponent from '@/components/SchemeParkComponent.vue';
import { mapState } from 'vuex';

export default {
  components: {
    LineComponent,
    QuestionComponent,
    SchemeParkComponent,
    AboutParkSliderComponent,
    AboutParkRulesComponent,
  },
  props: {
    screenWidth: Number,
  },
  computed: {
    ...mapState({
      schemeForTippy: (state) => state.schemePark.schemeForTippy,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
