<template>
	<div class="questions__question-wrapper" v-for="question in questions" :key="question.id">
		<details class="questions__question">
			<summary class="questions__question-title">{{ question.title }}</summary>
			<div class="questions__question-answer" v-html="question.answer" v-if="screenWidth > 640"></div>
			<div class="questions__question-answer" v-html="question.answerMobile" v-if="screenWidth <= 430"></div>
		</details>
		<LineComponent />
	</div>
</template>

<script>
import LineComponent from './LineComponent.vue';
import { mapState } from 'vuex';

export default {
	components: { LineComponent },

	props: {
		screenWidth: Number,
	},

	computed: {
		...mapState({
			questions: (state) => state.homeQuestions.questions,
		}),
	},
};
</script>

<style lang="scss" scoped>
.line {
	background-color: #57606f;
}
</style>
