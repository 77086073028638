<template>
	<table>
		<thead>
			<tr>
				<td>&nbsp;</td>
				<th>ПН</th>
				<th>ВТ</th>
				<th>СР</th>
				<th>ЧТ</th>
				<th>ПТ</th>
				<th>СБ</th>
				<th>ВС</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>10:00</th>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
				<td rowspan="6">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price schedule-btn__price--marginTop">350 руб.</span>
					</button>
				</td>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
				<td rowspan="6">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price schedule-btn__price--marginTop">350 руб.</span>
					</button>
				</td>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">начинающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">начинающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
			</tr>
			<tr>
				<th>11:00</th>
			</tr>
			<tr>
				<th>12:00</th>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
			</tr>
			<tr>
				<th>13:00</th>
			</tr>
			<tr>
				<th>14:00</th>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
			</tr>
			<tr>
				<th>15:00</th>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
				<td rowspan="5">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price">300 руб.</span>
					</button>
				</td>
			</tr>
			<tr>
				<th>16:00</th>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">начинающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">начинающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="4">
					<button class="schedule-btn ms_booking">
						<span class="schedule-btn__title">СВОБОДНОЕ КАТАНИЕ</span>
						<span class="schedule-btn__transport">самокат/ролики</span>
						<span class="schedule-btn__transportBooking">*bmx/МТВ по записи</span>
						<span class="schedule-btn__price schedule-btn__price--shortMarginTop">400 руб.</span>
					</button>
				</td>
			</tr>
			<tr>
				<th>17:00</th>
			</tr>
			<tr>
				<th>18:00</th>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">продолжающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
				<td rowspan="2">
					<button class="schedule-btn schedule-btn--blue ms_booking">
						<span class="schedule-btn__title">самокат-школа</span>
						<span class="schedule-btn__transport">начинающие</span>
						<img class="schedule-btn--blue__thumb" src="@/assets/img/thumbSchedule.svg" alt="thumb" />
					</button>
				</td>
			</tr>
			<tr>
				<th>19:00</th>
			</tr>
			<tr>
				<th>20:00</th>
				<td colspan="7"></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 15px;
	thead {
		td {
			background: #737373;
			width: 9%;
			text-align: center;
		}

		th {
			background: #252c37;
			width: 13%;
			text-align: center;
			color: #fff;
			font-size: 30px;
			font-style: italic;
			font-weight: 800;
			line-height: 30px;
			text-transform: uppercase;
			padding: 10px 0;
		}
	}

	tbody {
		th {
			background: #737373;
			color: #fff;
			padding: 10px 0;
			text-align: center;
			font-size: 30px;
			font-style: italic;
			font-weight: 800;
			line-height: 30px;
			text-transform: uppercase;
		}

		td {
			background: #d1d1d1;
			color: #fff;
			padding: 13px 0;
			text-align: center;
			font-size: 23px;
			font-style: italic;
			font-weight: 600;
			line-height: 24px;
			text-transform: uppercase;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
		}

		td[rowspan] {
			padding: 0 10px;
			background: #fb3100;
			text-align: left;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			height: auto;
		}

		td[rowspan='2'] {
			padding: 0 10px;
			background: #70c1ff;
			text-align: left;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			height: auto;
		}
		td[colspan='7'] {
			background: #e6e6e6;
		}
	}
	.individualSchedule__groupBtn {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		color: #d1d1d1;
		text-transform: uppercase;
	}
}

.schedule-btn {
	border: none;
	outline: none;
	cursor: pointer;
	background: #fb3100;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	gap: 15px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 800;
	text-align: left;

	&--blue {
		background: #70c1ff;
		position: relative;

		&__thumb {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	&__title {
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 0.02em;
	}
	&__transport {
		font-style: italic;
		font-size: 21px;
		line-height: 21px;
	}
	&__transportBooking {
		font-style: italic;
		font-size: 21px;
		line-height: 21px;
	}
	&__price {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 28px;
		line-height: 28px;
		margin-top: 80px;
		&::before {
			content: url('@/assets/img/money.svg');
		}

		&--marginTop {
			margin-top: 140px;
		}

		&--shortMarginTop {
			margin-top: 20px;
		}
	}
}

@media (max-width: 1686px) and (min-width: 1356px) {
	table {
	border-spacing: 10px;
	thead {
		th {
			font-size: 24px;
			line-height: 24px;
			padding: 10px 0;
		}
	}

	tbody {
		th {
			padding: 10px 0;
			font-size: 20px;
			line-height: 20px;
		}

		td {
			padding: 10px 0;
			font-size: 24px;
			line-height: 24px;
		}

		td[rowspan] {
			padding: 10px;
		}

		td[rowspan='2'] {
			padding: 0;
		}
	}
}

.schedule-btn {
	gap: 10px;

	&--blue {

		&__thumb {
			position: absolute;
			top: 0;
			right: 5px;
			width: 40px;
			height: 40px;
		}
	}

	&__title {
		font-size: 24px;
		line-height: 24px;
	}
	&__transport {
		font-size: 16px;
		line-height: 16px;
	}
	&__transportBooking {
		font-size: 16px;
		line-height: 16px;
	}
	&__price {
		gap: 0px;
		font-size: 24px;
		line-height: 24px;
		margin-top: 80px;
		&::before {
			content: url('@/assets/img/money125.svg');
			margin-top: 5px;
		}

		&--marginTop {
			margin-top: 130px;
		}

		&--shortMarginTop {
			margin-top: 30px;
		}
	}
}
}

@media (max-width: 1355px) and (min-width: 640px) {
	table {
	border-spacing: 5px;
	thead {
		th {
			font-size: 12px;
			line-height: 12px;
			padding: 5px 0;
		}
	}

	tbody {
		th {
			padding: 5px 0;
			font-size: 10px;
			line-height: 10px;
		}

		td {
			padding: 5px 0;
			font-size: 12px;
			line-height: 12px;
		}

		td[rowspan] {
			padding: 0;
		}

		td[rowspan='2'] {
			padding: 0;
		}
	}
}

.schedule-btn {
	gap: 5px;

	&--blue {

		&__thumb {
			position: absolute;
			top: 0;
			right: 0;
			width: 20px;
			height: 20px;
		}
	}

	&__title {
		font-size: 12px;
		line-height: 12px;
	}
	&__transport {
		font-size: 8px;
		line-height: 8px;
	}
	&__transportBooking {
		font-size: 8px;
		line-height: 8px;
	}
	&__price {
		gap: 3px;
		font-size: 12px;
		line-height: 12px;
		margin-top: 40px;
		&::before {
			content: url('@/assets/img/moneyTablet.svg');
			
		}

		&--marginTop {
			margin-top: 65px;
		}

		&--shortMarginTop {
			margin-top: 15px;
		}
	}
}
}


</style>
