<template>
  <section class="eventsPage">
    <div class="eventsPage__wrapper container">
      <div class="eventsPage__titleBlock">
        <h1 class="eventsPage__title">мероприятия</h1>
        <h2 class="eventsPage__title eventsPage__title--grey">
          <svg v-if="screenWidth > 1686" width="215" height="86" fill="none">
            <path
              fill="#E6E6E6"
              d="m171.927 0-14.819 14.822 17.364 17.366H0v20.96h174.472l-17.364 17.367 14.819 14.822 42.664-42.669L171.927 0Z"
            />
          </svg>
          <svg
            v-if="screenWidth >= 1356 && screenWidth <= 1686"
            width="172"
            height="69"
            viewBox="0 0 172 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M137.741 0.015625L125.885 11.873L139.777 25.7664L0.199219 25.7664V42.5343L139.777 42.5343L125.885 56.4277L137.741 68.2851L171.872 34.1504L137.741 0.015625Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg
            v-if="screenWidth >= 640 && screenWidth <= 1355"
            width="87"
            height="35"
            viewBox="0 0 87 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M69.3706 0.804688L63.4426 6.73335L70.3885 13.6801L0.599609 13.6801V22.064L70.3885 22.064L63.4426 29.0107L69.3706 34.9394L86.4358 17.8721L69.3706 0.804688Z"
              fill="#E6E6E6"
            />
          </svg>
          <svg v-if="screenWidth <= 430" width="73.855" height="29.787" fill="none">
            <path
              fill="#E6E6E6"
              d="m58.963 0-5.172 5.174 6.06 6.061H0v7.317h59.852l-6.061 6.061 5.172 5.174 14.892-14.894L58.963 0Z"
            />
          </svg>
          kss school
        </h2>
      </div>
    </div>
  </section>
  <section class="eventsPage__mainEvent container">
    <img
      v-if="screenWidth > 640"
      class="eventsPage__mainImg"
      src="@/assets/img/Events/mainEvent.webp"
      alt="mainEvent"
    />
    <img
      v-if="screenWidth <= 430"
      class="eventsPage__mainImg"
      src="@/assets/img/Events/mainEventMobile.webp"
      alt="mainEvent"
    />
    <div class="mainEvent__description">
      <h2>ДНЕВНИК ПРОГРЕССА 2022-2023</h2>
      <p>
        Учет успеваемости у каждого райдера ведется индивидуально- в виде дневника прогресса, куда в конце каждого
        занятия ставится балл за тренировку.Каждые три месяца подводятся итоги, где ученики получают призы от KSS за
        успехи.
      </p>
      <router-link to="/diary">
        читать далее
        <svg v-if="screenWidth > 1686" width="36" height="16" fill="none">
          <path
            fill="#fff"
            d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
          />
        </svg>
				<svg v-if="screenWidth >= 1356 && screenWidth <= 1686" width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.9645 6.97975C29.2769 6.66733 29.2769 6.1608 28.9645 5.84838L23.8733 0.757208C23.5609 0.444789 23.0544 0.444789 22.742 0.757208C22.4296 1.06963 22.4296 1.57616 22.742 1.88858L27.2675 6.41406L22.742 10.9395C22.4296 11.252 22.4296 11.7585 22.742 12.0709C23.0544 12.3833 23.5609 12.3833 23.8733 12.0709L28.9645 6.97975ZM0.798828 7.21406H28.3988V5.61406H0.798828V7.21406Z" fill="white" />
</svg>
        <svg
          v-if="screenWidth >= 640 && screenWidth <= 1355"
          width="15"
          height="6"
          viewBox="0 0 15 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4823 3.28675C14.6385 3.13054 14.6385 2.87727 14.4823 2.72106L11.9367 0.175479C11.7805 0.0192695 11.5272 0.0192695 11.371 0.175479C11.2148 0.331689 11.2148 0.584955 11.371 0.741164L13.6337 3.00391L11.371 5.26665C11.2148 5.42286 11.2148 5.67612 11.371 5.83233C11.5272 5.98854 11.7805 5.98854 11.9367 5.83233L14.4823 3.28675ZM0.399414 3.40391H14.1994V2.60391H0.399414V3.40391Z"
            fill="white"
          />
        </svg>

        <svg
          v-if="screenWidth <= 430"
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4157 0L13.4835 3.00665L16.9192 6.5296L0 6.52959V10.7814L16.9192 10.7814L13.4835 14.3044L16.4157 17.311L24.8569 8.65551L16.4157 0Z"
            fill="white"
          />
        </svg>
      </router-link>
    </div>
  </section>
  <LineComponent v-if="screenWidth <= 430" />
  <section class="eventsPage__allEvents container">
    <div v-for="(event, index) in allEvents" :key="event.id">
      <EventCardComponent v-if="screenWidth <= 430 && index < 3" :event="event" :screenWidth="screenWidth" />
      <EventCardComponent v-else-if="screenWidth > 640" :event="event" :screenWidth="screenWidth" />
    </div>

    <img v-if="screenWidth <= 430" class="thumb eventsPage__thumb1" src="@/assets/img/thumbs1.svg" alt="thumb" />
    <img v-if="screenWidth <= 430" class="thumb eventsPage__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
    <!-- <img v-if="screenWidth <= 430" class="thumb eventsPage__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb eventsPage__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb eventsPage__thumb5" src="@/assets/img/thumbs2.svg" alt="thumb" />
		<img v-if="screenWidth <= 430" class="thumb eventsPage__thumb6" src="@/assets/img/thumbs2.svg" alt="thumb" /> -->
  </section>
  <LineComponent v-if="screenWidth > 640" style="margin-bottom: 100px" />
  <LineComponent v-if="screenWidth <= 430" style="margin-bottom: 90px" />
</template>

<script>
import EventCardComponent from '@/components/EventCardComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import { mapState } from 'vuex';

export default {
  name: 'Events Page',
  components: { LineComponent, EventCardComponent },

  props: {
    screenWidth: Number,
  },

  computed: {
    ...mapState({
      allEvents: (state) => state.eventCards.allEventCards,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
