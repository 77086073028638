import { v4 as uuidv4 } from 'uuid';

const aboutParkPhotos = {
  state: {
    photos: [
      {
        id: uuidv4(),
        url: require('@/assets/img/parkPhoto/pic1.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/parkPhoto/pic2.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/parkPhoto/pic3.png'),
      },
      {
        id: uuidv4(),
        url: require('@/assets/img/parkPhoto/pic4.png'),
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default aboutParkPhotos;
