const educationStepCards = {
	state: {
		cards: [
			{
				id: 1,
				title: 'подготовительный',
				text: 'Основы контроля самоката для безопасного катания по парку. Закладывается фундамент для дальнейшего полноценного развития ребёнка как спортсмена.',
			},
			{
				id: 2,
				title: 'базовый',
				text: 'Основной  задачей является обучить правильной технике выполнения базовых элементов, а главное сделать это максимально безопасно. ',
			},
			{
				id: 3,
				title: 'продвинутый',
				text: 'Заложив изначально правильную технику и понимание элементов, мы помогаем в раскрытии способностей и индивидуального видения катания ребенка. ',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default educationStepCards;
