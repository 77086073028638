const schemePark = {
  state: {
    scheme: [
      {
        id: 1,
        title: 'вертвол',
        text: 'вертвол, высокий квотер с углом под 90°',
      },

      {
        id: 4,
        title: 'мини боул',
        text: 'изогнутый невысокий радиус, используется как разгон на пирамиды, подходит для отработки стоек и скольжения на коупинге (трубе), а так же есть место, для отработки трюков на стол.',
      },

      {
        id: 10,
        title: 'резиновый фан бокс',
        text: '',
      },
      {
        id: 11,
        title: 'резиновый квотер',
        text: '',
      },
    ],

    scheme2: [
      {
        id: 2,
        title: 'симпл',
        text: 'фан бокс с поднятым приземлением',
      },
      {
        id: 5,
        title: 'книжка',
        text: '',
      },
      {
        id: 6,
        title: 'бокс',
        text: '',
      },
      {
        id: 7,
        title: 'классическая пирамида',
        text: '',
      },
      {
        id: 12,
        title: 'квотера',
        text: 'с которых можно учить трюки прыгая в резиновый квотер, или вылетать при катании в линии с пирамидой.',
      },
    ],
    scheme3: [
      {
        id: 3,
        title: 'квотер',
        text: 'большой радиус для разворота',
      },
      {
        id: 8,
        title: 'воллрайд',
        text: 'радиус со стеной предназначенной для трюков воллтеп, воллрайд — вставать на стену и отпрыгивать обратно.',
      },
      {
        id: 9,
        title: 'двухуровневая разгонка',
        text: '',
      },
      {
        id: 13,
        title: 'рампа',
        text: 'фигура из двух радиусов, в которой нарабатывается выносливость и стабильность',
      },
    ],

    schemeMobile: [
      {
        id: 1,
        title: 'вертвол',
        text: 'вертвол, высокий квотер с углом под 90°',
      },
      {
        id: 2,
        title: 'симпл',
        text: 'фан бокс с поднятым приземлением',
      },
      {
        id: 3,
        title: 'квотер',
        text: 'большой радиус для разворота',
      },
      {
        id: 4,
        title: 'мини боул',
        text: 'изогнутый невысокий радиус, используется как разгон на пирамиды, подходит для отработки стоек и скольжения на коупинге (трубе), а так же есть место, для отработки трюков на стол.',
      },
      {
        id: 5,
        title: 'книжка',
        text: '',
      },
      {
        id: 6,
        title: 'бокс',
        text: '',
      },
      {
        id: 7,
        title: 'классическая пирамида',
        text: '',
      },
      {
        id: 8,
        title: 'воллрайд',
        text: 'радиус со стеной предназначенной для трюков воллтеп, воллрайд — вставать на стену и отпрыгивать обратно.',
      },
      {
        id: 9,
        title: 'двухуровневая разгонка',
        text: '',
      },
      {
        id: 10,
        title: 'резиновый фан бокс',
        text: '',
      },
      {
        id: 11,
        title: 'резиновый квотер',
        text: '',
      },
      {
        id: 12,
        title: 'квотера',
        text: 'с которых можно учить трюки прыгая в резиновый квотер, или вылетать при катании в линии с пирамидой.',
      },
      {
        id: 13,
        title: 'рампа',
        text: 'фигура из двух радиусов, в которой нарабатывается выносливость и стабильность',
      },
    ],

    schemeForTippy: [
      {
        id: 1,
        title: 'вертвол',
        text: 'вертвол, высокий квотер с углом под 90°',
      },
      {
        id: 2,
        title: 'симпл',
        text: 'симпл - фан бокс с поднятым приземлением',
      },

      {
        id: 3,
        title: 'квотер',
        text: 'квотер',
      },
      {
        id: 4,
        title: 'мини боул',
        text: 'мини боул - изогнутый невысокий радиус, используется как разгон на пирамиды, подходит для отработки стоек и скольжения на коупинге (трубе), а так же есть место, для отработки трюков на стол.',
      },
      {
        id: 5,
        title: 'книжка',
        text: 'книжка',
      },
      {
        id: 6,
        title: 'бокс',
        text: 'бокс',
      },
      {
        id: 7,
        title: 'классическая пирамида',
        text: 'классическая пирамида',
      },
      {
        id: 8,
        title: 'воллрайд',
        text: 'воллрайд - радиус со стеной предназначенной для трюков воллтеп, воллрайд — вставать на стену и отпрыгивать обратно.',
      },
      {
        id: 9,
        title: 'двухуровневая разгонка',
        text: 'двухуровневая разгонка',
      },
      {
        id: 10,
        title: 'резиновый фан бокс',
        text: 'резиновый фан бокс',
      },
      {
        id: 11,
        title: 'резиновый квотер',
        text: 'резиновый квотер',
      },
      {
        id: 12,
        title: 'квотера',
        text: 'квотера - с которых можно учить трюки прыгая в резиновый квотер, или вылетать при катании в линии с пирамидой.',
      },
      {
        id: 13,
        title: 'рампа',
        text: 'рампа - фигура из двух радиусов, в которой нарабатывается выносливость и стабильность',
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

export default schemePark;
