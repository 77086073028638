<template>
  <swiper
    :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
    :modules="modules"
    :slidesPerView="screenWidth > 640 ? 3.5 : 1"
    :centeredSlides="false"
    :spaceBetween="15"
    :grabCursor="true"
    class="mySwiper feedback__swiper"
  >
    <swiper-slide v-for="card in feedbackCards" :key="card.id">
      <article class="feedback__card">
        <div class="feedback__card-titleBlock">
          <div class="feedback__nameBlock">
            <h3 class="feedback__name">{{ card.name }}</h3>
            <svg v-if="screenWidth > 1686" xmlns="http://www.w3.org/2000/svg" width="117" height="23" fill="none">
              <path
                fill="#252C37"
                d="m11.597.93 2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.408-4.655L5.19 20.65l2.447-7.532L1.23 8.463h7.92L11.597.931ZM35.22.93l2.448 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92L35.22.931ZM58.846.93l2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92L58.845.931ZM82.47.93l2.448 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.448-7.532-6.408-4.656h7.92L82.471.931ZM106.096.93l2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92l2.448-7.532Z"
              />
            </svg>
            <svg
              v-if="screenWidth <= 1686 && screenWidth >= 1356"
              width="94"
              height="19"
              viewBox="0 0 94 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.27565 0.945312L11.2336 6.97135H17.5698L12.4437 10.6956L14.4017 16.7217L9.27565 12.9974L4.1496 16.7217L6.10757 10.6956L0.981522 6.97135H7.31767L9.27565 0.945312Z"
                fill="#252C37"
              />
              <path
                d="M28.1741 0.945312L30.1321 6.97135H36.4682L31.3422 10.6956L33.3001 16.7217L28.1741 12.9974L23.048 16.7217L25.006 10.6956L19.88 6.97135H26.2161L28.1741 0.945312Z"
                fill="#252C37"
              />
              <path
                d="M47.0725 0.945312L49.0305 6.97135H55.3666L50.2406 10.6956L52.1986 16.7217L47.0725 12.9974L41.9465 16.7217L43.9044 10.6956L38.7784 6.97135H45.1145L47.0725 0.945312Z"
                fill="#252C37"
              />
              <path
                d="M65.9788 0.945312L67.9367 6.97135H74.2729L69.1468 10.6956L71.1048 16.7217L65.9788 12.9974L60.8527 16.7217L62.8107 10.6956L57.6846 6.97135H64.0208L65.9788 0.945312Z"
                fill="#252C37"
              />
              <path
                d="M84.8772 0.945312L86.8352 6.97135H93.1713L88.0453 10.6956L90.0033 16.7217L84.8772 12.9974L79.7512 16.7217L81.7091 10.6956L76.5831 6.97135H82.9192L84.8772 0.945312Z"
                fill="#252C37"
              />
            </svg>

            <svg
              v-if="screenWidth < 1356 && screenWidth >= 640"
              width="47"
              height="10"
              viewBox="0 0 47 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.63978 0.472656L5.61877 3.48567H8.78684L6.22381 5.34782L7.2028 8.36084L4.63978 6.49869L2.07675 8.36084L3.05574 5.34782L0.492714 3.48567H3.66079L4.63978 0.472656Z"
                fill="#252C37"
              />
              <path
                d="M14.089 0.472656L15.068 3.48567H18.2361L15.673 5.34782L16.652 8.36084L14.089 6.49869L11.526 8.36084L12.505 5.34782L9.94193 3.48567H13.11L14.089 0.472656Z"
                fill="#252C37"
              />
              <path
                d="M23.5402 0.472656L24.5192 3.48567H27.6872L25.1242 5.34782L26.1032 8.36084L23.5402 6.49869L20.9771 8.36084L21.9561 5.34782L19.3931 3.48567H22.5612L23.5402 0.472656Z"
                fill="#252C37"
              />
              <path
                d="M32.9894 0.472656L33.9684 3.48567H37.1364L34.5734 5.34782L35.5524 8.36084L32.9894 6.49869L30.4264 8.36084L31.4053 5.34782L28.8423 3.48567H32.0104L32.9894 0.472656Z"
                fill="#252C37"
              />
              <path
                d="M42.4406 0.472656L43.4195 3.48567H46.5876L44.0246 5.34782L45.0036 8.36084L42.4406 6.49869L39.8775 8.36084L40.8565 5.34782L38.2935 3.48567H41.4616L42.4406 0.472656Z"
                fill="#252C37"
              />
            </svg>

            <svg v-if="screenWidth <= 430" width="73" height="15" fill="none">
              <path
                fill="#252C37"
                d="m7.142.959 1.51 4.649h4.888L9.586 8.481l1.51 4.65-3.954-2.874-3.955 2.873 1.51-4.649L.744 5.608H5.63L7.141.959ZM21.722.959l1.51 4.649h4.889l-3.955 2.873 1.51 4.65-3.954-2.874-3.954 2.873 1.51-4.649-3.955-2.873h4.889l1.51-4.649ZM36.303.959l1.51 4.649h4.89l-3.956 2.873 1.51 4.65-3.954-2.874-3.954 2.873 1.51-4.649-3.955-2.873h4.889l1.51-4.649ZM50.884.959l1.51 4.649h4.89l-3.956 2.873 1.511 4.65-3.955-2.874-3.954 2.873 1.51-4.649-3.954-2.873h4.888l1.51-4.649ZM65.465.959l1.51 4.649h4.889l-3.955 2.873 1.51 4.65-3.954-2.874-3.955 2.873 1.511-4.649-3.955-2.873h4.889l1.51-4.649Z"
              />
            </svg>
          </div>
          <span class="feedback__date">{{ card.date }}</span>
        </div>

        <p class="feedback__text">{{ card.text }}</p>
      </article>
    </swiper-slide>
    <div class="feedback__navigation">
      <div class="swiper-button-prev feedback__navigation-prev"></div>
      <div class="swiper-button-next feedback__navigation-next"></div>
    </div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
  props: {
    screenWidth: Number,
  },

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },

  computed: {
    ...mapState({
      feedbackCards: (state) => state.feedbackCards.feedbackCards,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';
</style>
