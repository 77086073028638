<template>
	<ul class="price-list">
		<li class="price-details" v-for="item in price" :key="item.id">
			<svg class="price-tippy" width="19" height="19" fill="none">
				<path
					fill="#0091FF"
					d="M8.63 13.589h1.795V8.206H8.63v5.383Zm.898-7.178a.869.869 0 0 0 .64-.258.865.865 0 0 0 .257-.639.869.869 0 0 0-.259-.64.865.865 0 0 0-.638-.257.869.869 0 0 0-.64.258.865.865 0 0 0-.258.64c0 .253.087.466.259.639a.865.865 0 0 0 .639.257Zm0 11.664a8.73 8.73 0 0 1-3.5-.707A9.074 9.074 0 0 1 3.18 15.45a9.054 9.054 0 0 1-1.917-2.85 8.752 8.752 0 0 1-.707-3.498c0-1.241.235-2.408.707-3.5A9.074 9.074 0 0 1 3.18 2.756 9.054 9.054 0 0 1 6.029.838 8.752 8.752 0 0 1 9.528.13c1.24 0 2.407.236 3.499.707a9.074 9.074 0 0 1 2.848 1.917 9.066 9.066 0 0 1 1.919 2.849 8.72 8.72 0 0 1 .706 3.499 8.73 8.73 0 0 1-.707 3.499 9.074 9.074 0 0 1-1.918 2.848 9.066 9.066 0 0 1-2.848 1.919 8.72 8.72 0 0 1-3.5.706Z"
				/>
			</svg>
			<span class="price-title">{{ item.title }}</span>
			<div class="price-people" v-html="item.people"></div>
			<span class="price-quantity">{{ item.quantity }}</span>
			<span class="price-duration">{{ item.duration }}</span>
			<span class="price-currentPrice">{{ item.currentPrice }} ₽</span>
			<button class="price-btn ms_booking">записаться</button>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		price: Array,
	},

	mounted() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = `https://w388672.yclients.com/widgetJS`;
		script.charset = 'UTF-8';
		script.crossOrigin = 'anonymous';
		document.body.appendChild(script);
	},
};
</script>

<style lang="scss" scoped></style>
