import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import AboutScooterPage from '@/pages/AboutScooterPage.vue';
import AboutPage from '@/pages/AboutPage.vue';
import AboutParkPage from '@/pages/AboutParkPage.vue';
import CoachPage from '@/pages/CoachPage.vue';
import CoachesPage from '@/pages/CoachesPage.vue';
import ContactsPage from '@/pages/ContactsPage.vue';
import EventsPage from '@/pages/EventsPage.vue';
import GroupPage from '@/pages/GroupPage.vue';
import IndividualPage from '@/pages/IndividualPage.vue';
import MediaPage from '@/pages/MediaPage.vue';
import ProgressDiaryPage from '@/pages/ProgressDiaryPage.vue';
import RulesPage from '@/pages/RulesPage.vue';
import QuestionsPage from '@/pages/QuestionsPage.vue';
import ServicesAndPricePage from '@/pages/ServicesAndPricePage.vue';
import ServicesIndividual from '@/pages/ServicesAndPages/Services-IndividualPage.vue';
import ServicesGroup from '@/pages/ServicesAndPages/Services-GroupPage.vue';
import ServicesPark from '@/pages/ServicesAndPages/Services-ParkPage.vue';
import ServicesRental from '@/pages/ServicesAndPages/Services-RentalPage.vue';
import ServicesFreeDriving from '@/pages/ServicesAndPages/Services-FreeDrivingPage.vue';
import ServicesEvents from '@/pages/ServicesAndPages/Services-EventsPage.vue';
import TrainingPage from '@/pages/TrainingPage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/scooter-sport',
    name: 'aboutScooter',
    component: AboutScooterPage,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage,
  },
  {
    path: '/events',
    name: 'events',
    component: EventsPage,
  },
  {
    path: '/individual',
    name: 'individual',
    component: IndividualPage,
  },
  {
    path: '/diary',
    name: 'diary',
    component: ProgressDiaryPage,
  },
  {
    path: '/group',
    name: 'group',
    component: GroupPage,
  },
  {
    path: '/kss-park',
    name: 'aboutPark',
    component: AboutParkPage,
  },
  {
    path: '/media',
    name: 'media',
    component: MediaPage,
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesPage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: QuestionsPage,
  },
  {
    path: '/services-and-price',
    name: 'Services And Price',
    component: ServicesAndPricePage,
    children: [
      { path: 'individual', component: ServicesIndividual },
      { path: 'group', component: ServicesGroup },
      { path: 'park', component: ServicesPark },
      { path: 'rental', component: ServicesRental },
      { path: 'free-driving', component: ServicesFreeDriving },
      { path: 'events', component: ServicesEvents },
    ],
  },
  {
    path: '/team',
    name: 'team',
    component: CoachesPage,
  },
  {
    path: '/team/akimov',
    component: CoachPage,
  },
  {
    path: '/team/tarabrin',
    component: CoachPage,
  },
  {
    path: '/team/mihin',
    component: CoachPage,
  },
  {
    path: '/team/kozlov',
    component: CoachPage,
  },
  {
    path: '/team/fazolov',
    component: CoachPage,
  },
  {
    path: '/team/basanskii',
    component: CoachPage,
  },
  {
    path: '/team/soloviev',
    component: CoachPage,
  },
  {
    path: '/team/kravchenko',
    component: CoachPage,
  },
  {
    path: '/team/popelnuha',
    component: CoachPage,
  },
  {
    path: '/team/volkov',
    component: CoachPage,
  },
  {
    path: '/team/hohlov',
    component: CoachPage,
  },
  {
    path: '/team/chilchagov',
    component: CoachPage,
  },
  {
    path: '/team/manannikov',
    component: CoachPage,
  },
  {
    path: '/team/kochin',
    component: CoachPage,
  },
  {
    path: '/team/new',
    component: CoachPage,
  },
  {
    path: '/training',
    name: 'training',
    component: TrainingPage,
  },
  {
    path: '/:CatchAll(.*)',
    name: '404',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path.startsWith('/services-and-price')) {
      // Не прокручиваем для маршрута /services-and-price и его дочерних маршрутов
      return false;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
