import { v4 as uuidv4 } from 'uuid';

const aboutSubjects = {
	state: {
		subjects: [
			{
				id: uuidv4(),
				title: 'Парк',
				text: `катание по специально оборудованной <br /> экстрим-площадке: скейт-парке.`,
			},
			{
				id: uuidv4(),
				title: 'стрит',
				text: 'катание по уличным спотам: ступени, <br /> перила, парапеты, дропы и т. д.',
			},
			{
				id: uuidv4(),
				title: 'флэт',
				text: 'исполнение трюков <br /> на плоскости.',
			},
			{
				id: uuidv4(),
				title: 'биг-эйр',
				text: 'специально сконструированный <br /> большой трамплин.',
			},
			{
				id: uuidv4(),
				title: 'дёрт',
				text: 'катание по горным спускам и дёрт-паркам с <br /> трамплинами на специально оборудованном самокате.',
			},
			{
				id: uuidv4(),
				title: 'сноускутеринг',
				text: 'катание по снегу на самокате <br /> со специальными досками.',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default aboutSubjects;
