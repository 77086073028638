<template>
	<details class="faq__question" v-for="question in questions" :key="question.id">
		<summary class="faq__question-title">{{ question.title }}</summary>
		<div class="faq__question-answer" v-html="question.answer" v-if="screenWidth > 640"></div>
		<div class="faq__question-answer" v-html="question.answerMobile" v-if="screenWidth <= 430"></div>
	</details>
</template>

<script>
import LineComponent from './LineComponent.vue';
import { mapState } from 'vuex';

export default {
	components: { LineComponent },

	props: {
		screenWidth: Number,
	},

	computed: {
		...mapState({
			questions: (state) => state.faq.questions,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
